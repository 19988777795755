import classnames from 'classnames';
import styled, { css, keyframes } from 'styled-components';

import { DescriptionBoxStyle } from '@legacyApp/client/modules/style/components/descriptionBox.style';
import { borderRadius, boxNoHeight } from '@legacyApp/client/modules/style/defaults';
import { fontSize } from '@legacyApp/client/modules/style/mixins/fontSize';

import { AwesomeIcon } from '@common/components/icons/AwesomeIcon';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { encodeSvg } from '@legacyApp/client/components/svg/Svg';
import { PaginationStyled } from '@ui/pagination/Pagination.styled';

const RowAnimation = (props) => keyframes`
    0% {
	    ${props.$rowIndex === 0 ? 'opacity: 0' : ''};
	    transform: translateY(${`calc(${props.$rowIndex - 1} * 100%)`});
    }
		50% {
			${props.$rowIndex === 0 ? 'opacity: 0' : ''};
		}
    100% {
			${props.$rowIndex === 0 ? 'opacity: 1' : ''};
			transform: translateY(${`calc(${props.$rowIndex} * 100%)`});
    }
`;

const CellHeight = (callback) => (props) => {
	const height = parseFloat(
		props.theme.layout.table.cellHeight.replace('px', ''),
	);

	return css`
		${callback(height * 2)};
	`;
};

export const StyledTableCell = styled.td.attrs((props) => ({
	className: classnames('table__cell no-pointer', props.className),
}))`
	align-items: center;
	box-sizing: border-box;
	display: flex;
	float: left;
	padding-left: 7px;
	min-width: 10px;
	vertical-align: middle;

	${CellHeight(
	(height) => css`
			height: ${height}px;
		`,
)}

	&:last-of-type {
		padding-right: 7px;
	}

	.input-wrapper {
		.relative {
			width: 100%;

			input {
				color: ${(props) => props.theme.colors.text}!important;
				font-family: inherit !important;
				font-size: inherit !important;
			}
		}

		.buttons-box {
			button {
				margin: 5px;
				padding: 0;
				width: 44px;

				&:last-of-type {
					margin-right: 0;
				}
			}
		}
	}

	tr > span > svg {
		height: 1em;
	}

	.text {
		display: block;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
		line-height: 1;

		> * {
			display: inline-block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;
		}

		> div {
			align-items: center;
			display: flex;
		}
	}

	@media (min-width: ${(props) => props.theme.media.minWidthDesktop}) {
		padding-left: 15px;

		&:last-of-type {
			padding-right: 15px;
		}
	}

	.input-wrapper {
		@media (max-width: ${(props) => props.theme.media.maxWidthTablet}) {
			.relative {
				width: 100%;

				input {
					height: 100%;
					min-height: 20px;
					padding: 5px;
				}
			}

			.buttons-box {
				button {
					width: 24px;
					margin: 2px;
					height: calc(100% - 4px);

					&:last-of-type {
						margin-right: -7px;
					}
				}
			}
		}
	}
`;

export const tableNoAnimation = css`
	.table {
		height: auto;
		width: 100%;

		&__tbody {
			height: auto;
		}

		&__row {
			animation: none !important;
			position: relative;
			transform: none;
		}
	}
`;

export interface StyledTableRowProps {
	$rowIndex?: number;
	$isAdditional?: boolean;
	as?: string;
}

export const StyledTableRow = styled.tr.attrs<StyledTableRowProps>((props) => ({
	className: classnames('table__row', props.className),
})) <StyledTableRowProps>`
	align-items: center;
	background: transparent;
	display: flex;
	float: left;
	min-height: ${(props) => props.theme.layout.table.cellHeight};
	min-width: 100%;
	transition: 0.4s ease-in-out;
	width: auto;
	position: relative;

	${(props) =>
		props.as === 'div'
			? css`
					animation: ${RowAnimation} 0.4s ease-in-out 1;
					animation-fill-mode: forwards;
					position: absolute;
					transform: translateY(${`calc(${props.$rowIndex} * 100%)`});

					${StyledTHead} > & {
						position: relative !important;
					}
			  `
			: ''}

	${(props) =>
		props.$isAdditional
			? css`
					margin: 40px 0 0 !important;

					&:before {
						content: url(${encodeSvg(
				AwesomeIcon({ icon: faEllipsisV, color: 'white' }),
			)});
						display: block;
						height: 10px;
						left: 50%;
						position: absolute;
						top: -35px;
						transform: translateX(-50%);
						width: 7px;
					}
			  `
			: ''};
`;

export interface StyledTBodyProps {
	$length?: number;
	$rowMargin?: number;
	as?: string;
}

export const StyledTBody = styled.tbody.attrs<StyledTBodyProps>((props) => ({
	className: classnames('table__tbody text-style-sm-medium', props.className),
})) <StyledTBodyProps>`
	float: left;
	min-width: 100%;
	position: relative;
	width: auto;
	${(props) =>
		CellHeight(
			(height) => css`
				height: ${props.$length
					? `${props.$length * (height + (props.$rowMargin || 0))}px`
					: 'auto'};
			`,
		)};
	}
`;

export const StyledNoResultTableCell = styled(StyledTableCell)`
	align-items: center !important;
	display: flex !important;
	height: auto !important;
	justify-content: center;
	padding: 10px;
	width: 100%;

	.no-data-info {
		left: 50%;
		padding-top: 20px;
		position: absolute;
		text-align: center;
		top: 0;
		transform: translateX(-50%);
		width: 300px;

		i,
		svg {
			font-size: 30px;
		}
	}

	${(props) =>
		fontSize({
			minWidth: props.theme.media.minWidthDesktop,
		})};
`;

export const StyledTableRowDetails = styled.div.attrs((props) => ({
	className: classnames('table__row--details', props.className),
}))`
	width: 100% !important;
	padding: 0 15px;

	${DescriptionBoxStyle} {
		margin-bottom: 10px !important;
		padding: 5px 15px;
		border: 1px solid ${(props) => props.theme.colors.tableBorder};
	}

	button {
		margin: 0 0 10px;
	}

	@media (max-width: ${(props) => props.theme.media.maxWidthVerySmall}) {
		padding: 0 5px;

		${DescriptionBoxStyle} {
			padding: 0 5px;
		}
	}
`;

export const StyledTHead = styled.thead.attrs((props) => ({
	className: classnames(
		'table__thead text text-style-xs-medium',
		props.className,
	),
}))`
	float: left;
	width: auto;
	position: relative;
	overflow: hidden;
	min-width: 100%;

	& ${StyledTableRow} {
		min-height: unset;
		animation: unset !important;
	}

	& ${StyledTableCell} {
		color: var(--color-dark-100);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		display: block;
		height: auto;
		text-transform: capitalize;

		&[data-sort='title'] {
			padding-left: 38px;

			@media (max-width: 370px) {
				padding-left: 33px;
			}
		}
	}
`;

export interface StyledTableProps {
	$animationEnabled?: boolean;
	$noResults?: boolean;
	as?: string;
	name?: string;
}

export const StyledTable = styled.table.attrs<StyledTableProps>((props) => ({
	className: classnames('table', props.className),
})) <StyledTableProps>`
	box-sizing: border-box;
	float: left;
	height: unset;
	overflow: auto;
	width: 100%;

	* {
		box-sizing: border-box;
	}

	${(props) =>
		!props.$animationEnabled
			? css`
					${StyledTableRow} {
						animation: unset !important;
					}
			  `
			: null}

	${(props) =>
		props.$noResults
			? css`
					overflow-x: hidden;
					width: 100%;

					${StyledTBody} > ${StyledTableRow} {
						${CellHeight(
				(height) => css`
								height: ${height}px;
							`,
			)}
						position: relative;

						&:hover {
							background-color: unset;
						}
					}
			  `
			: ''}

	.loader--active {
		min-height: 20px;
	}
`;

export const StyledTableNoScroll = styled(StyledTable).attrs<StyledTableProps>(
	(props) => ({
		className: classnames('table__no-scroll', props.className),
	}),
) <StyledTableProps>`
	overflow-y: hidden;
	width: 100%;
`;

export const StyledTableLoader = styled.div.attrs((props) => ({
	className: classnames('table__loader', props.className),
}))`
	.loader--active {
		${borderRadius};
		background: ${(props) => props.theme.colors.inputButton};
		font-size: 20px;
		position: absolute;
		width: 50px;
		height: 50px;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
`;

export const StyledTableWrapper = styled.div.attrs((props) => ({
	className: classnames('table-wrapper', props.className),
}))`
	width: 100%;
	overflow: auto;

	${PaginationStyled} {
		margin-top: 20px;
	}
`;

export const StyledTabsTableWrapper = styled.div.attrs((props) => ({
	className: classnames('tabs-table-wrapper', props.className),
}))`
	${boxNoHeight};

	${StyledTable} {
		height: calc(100% - 45px);
	}

	[class*='page-']:not(.chat-visible):not(.left-menu-visible) & {
		${boxNoHeight};
	}
`;
