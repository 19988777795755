export const NEXT_CONFIG = {
	DEV: process.env.DEV,
	TEST: process.env.TEST,
	PRODUCTION: process.env.PRODUCTION,
	API_URL: process.env.API_URL,
	SOCKET_HOST: process.env.SOCKET_HOST,
	SOCKET_PORT: process.env.SOCKET_PORT,
	PUSHER_ID: process.env.PUSHER_ID,
	RELEASE_VERSION: process.env.RELEASE_VERSION,
	DICE_URL: process.env.DICE_URL,
	SPORTS_URL: process.env.SPORTS_URL,
	AUTOBET_URL: process.env.AUTOBET_URL,
	DISABLE_SOURCEMAPS: process.env.DISABLE_SOURCEMAPS,
	SLOTS_URL: process.env.SLOTS_URL,
	CLOUD_LOADER: process.env.CLOUD_LOADER,
	PUBLIC_SRC: process.env.PUBLIC_SRC,
	APP_ENV: process.env.APP_ENV,
	GIT_INFO: process.env.GIT_INFO,
	ENABLE_PLINKO_MOCK: process.env.ENABLE_PLINKO_MOCK,
	SPORTS_IFRAME_URL: process.env.SPORTS_IFRAME_URL,
} as const;
