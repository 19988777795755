import { css } from 'styled-components';

import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { theme } from '@legacyApp/client/modules/style/theme';
import { VIP_LEVELS_COLORS } from '@modules/vip/constants/vip.config';

export const buttonRound = (size: string) => {
	return css`
		background-color: ${theme.colors.inputBackgroundSolid};
		border-radius: 100%;
		height: ${size}!important;
		padding: 10px;
		width: ${size}!important;
	`;
};

export const getLevelColor = (level: number, noUnranked = false) => {
	const colorLabel =
		!level && !noUnranked
			? 'unranked'
			: level
			? VIP_LEVELS_COLORS[level]
			: null;
	if (!colorLabel) {
		return false;
	}
	return theme.colors.level[colorLabel];
};

export const customColorLayout = (color: string) => {
	return css`
		background-color: ${color};

		.main {
			background-color: ${color};
		}

		.header {
			background-color: transparent;

			&.scrolled,
			&.dark {
				background-color: rgba(${color}, 0.85);
			}
		}

		h2 {
			border-color: rgba(255, 255, 255, 0.35);
		}
	`;
};

export const mediumLayout = customColorLayout('#18263b');

export const sectionsPadding = (width, viewportPadding = '0') => {
	return css`
		@media (min-width: ${width}) and (max-width: ${calcStyle({
				a: width,
				b: viewportPadding,
			})}) {
			padding-left: 15px;
			padding-right: 15px;
		}

		@media (min-width: ${calcStyle({ a: width, b: viewportPadding })}) {
			padding-left: calc(((100% - ${width}) / 2) + 15px);
			padding-right: calc(((100% - ${width}) / 2) + 15px);
		}
	`;
};

export const borderRadiusValue = (callback) => {
	return css`
		${(props) => callback(props.theme.layout.borderRadius, props)};
	`;
};

export const textGradient = (gradientString: string, defaultColor: string) => {
	return css`
		// eslint-disable-next-line no-alert
		background: ${defaultColor}; /* fallback for old browsers */
		background: ${gradientString}; /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
		background-clip: text;
		-webkit-background-clip: text;
		text-fill-color: transparent;
		-webkit-text-fill-color: transparent;
		color: transparent;
	`;
};

export const spaceTypeSide = (
	spaceType: string,
	side: string,
	size: number,
	important?: boolean,
) => {
	return css`
		.${spaceType}-${side}-${size}${important ? '-important' : ''} {
			${spaceType}-${side}: ${size}px${important ? '!important' : ''};
		}
	`;
};

export const spaces = (size: number) => {
	return css`
		${spaceTypeSide('margin', 'left', size)};
		${spaceTypeSide('margin', 'top', size)};
		${spaceTypeSide('margin', 'bottom', size)};
		${spaceTypeSide('margin', 'right', size)};
		${spaceTypeSide('margin', 'left', size, true)};
		${spaceTypeSide('margin', 'top', size, true)};
		${spaceTypeSide('margin', 'bottom', size, true)};
		${spaceTypeSide('margin', 'right', size, true)};
		${spaceTypeSide('padding', 'left', size)};
		${spaceTypeSide('padding', 'top', size)};
		${spaceTypeSide('padding', 'bottom', size)};
		${spaceTypeSide('padding', 'right', size)};
		${spaceTypeSide('padding', 'left', size, true)};
		${spaceTypeSide('padding', 'top', size, true)};
		${spaceTypeSide('padding', 'bottom', size, true)};
		${spaceTypeSide('padding', 'right', size, true)};
	`;
};

export const handleHover = (styles) => {
	/* This feature is replaced by tailwind (WOL-3984) - input component (src/ui/components/link/index.tsx).
	In the case of mobile, we use "active" for all styles passed to handleHover,
	and for desktop and pointer devices we use "hover". In the case of input, it was resolved
	as follows: "pointer-events-none active:underline minWidthDesktop:pointer-events-auto minWidthDesktop:hover:underline
	minWidthDesktop:active:no-underline". Match the styles passed to handleHover for the following use
	by replacing underline */

	return css`
		@media (hover: hover) and (pointer: fine) {
			&:hover {
				${styles};
			}
		}

		@media not (hover: hover) and (pointer: fine) {
			&:active {
				${styles};
			}
		}
	`;
};
