import { FunctionComponent } from 'react';
import { NEXT_CONFIG } from '@common/constants/config/nextConfig';

export const isObjectSrc = (src: string): boolean =>
	src && (typeof src !== 'string' || src.indexOf('data:image/') > -1);

export const parseImageSrc = (src: string): string => {
	if (isObjectSrc(src)) {
		return src;
	}
	if (src.indexOf('http') > -1) {
		return src;
	}
	if (src && src[0] !== '/') {
		src = `/${src}`;
	}
	// return `https://storage.googleapis.com/wolfbet-static${src}`;
	return `${NEXT_CONFIG.PUBLIC_SRC || ''}${src}`;
};

export interface ImgProps {
	src: string;
	alt: string;
	className?: string;
	style?: any;
}

export const Image: FunctionComponent<ImgProps> = ({ src, alt, ...props }) => {
	return (
		<img {...props} src={parseImageSrc(src)} alt={src ? alt : undefined} />
	);
};
