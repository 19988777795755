import { useCallback, useContext, useEffect } from 'react';
import { useForceUpdate } from '@legacyApp/hooks/app/useForceRerender';
import { useAppMobile } from '@common/selectors/app.selectors';
import { SideMenuContext } from '../context/SideMenu.context';

export const useSideMenu = () => {
	const { isOpen, setIsOpen } = useContext(SideMenuContext);
	const isMobile = useAppMobile();

	const update = useForceUpdate();

	useEffect(() => {
		update();
	}, [isOpen, update]);

	const closeOnMobile = useCallback(() => {
		if (isMobile) {
			setIsOpen(false);
		}
	}, [isMobile, setIsOpen]);

	return {
		isOpen,
		setIsOpen,
		closeOnMobile,
	};
};
