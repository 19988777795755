import styled, { css } from 'styled-components';
import { currencyLogoStyle } from '../../modules/style/mixins/currencyLogoStyle';
import { calcStyle } from '../../modules/style/methods/calcStyle';
import { chatViewport } from '../../modules/style/methods/chatViewport';

export const CurrencyLogoStyle = styled.i.attrs(() => ({
	className: 'currency-logo',
}))`
	align-items: center;
	display: inline-flex;
	justify-content: center;
	text-align: left;
	overflow: hidden;
	${currencyLogoStyle({ size: '15px', important: false })};
	${chatViewport(
		(viewportPadding) => css`
			@media (min-width: ${(props) =>
					calcStyle({
						a: props.theme.media.minWidthDesktop,
						b: viewportPadding,
					})}) {
				${currencyLogoStyle({ size: '20px', important: false })}
			}
		`,
	)};
`;
