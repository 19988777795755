const serverConfig = {
	appPort: process.env.APP_PORT || process.env.PORT,
	apiKey: process.env.API_KEY,
	environment: process.env.NODE_ENV,
	disableAccessLock: process.env.DISABLE_ACCESS_LOCK,
	isProduction:
		process.env.APP_ENV !== 'development' &&
		process.env.NODE_ENV !== 'development',
	isLocal:
		process.env.APP_ENV === 'development' &&
		process.env.NODE_ENV === 'development',
	isStaging:
		process.env.APP_ENV === 'development' &&
		process.env.NODE_ENV !== 'development',
	enableRateLimiter: process.env.ENABLE_RATE_LIMITER,
	noCacheService: process.env.NO_CACHE_SERVICE,
	redis: process.env.NO_CACHE_SERVICE
		? null
		: {
				port: process.env.REDIS_PORT,
				host: process.env.REDIS_HOST,
				password: process.env.REDIS_PASSWORD,
		  },
	storageBucketId: {
		staticFiles: process.env.BUCKET_ID,
	},
	newRelic: {
		appName: process.env.NEW_RELIC_APP_NAME,
	},
	enableValidateAppUrlMiddleware:
		process.env.ENABLE_VALIDATE_APP_URL_MIDDLEWARE,
};

module.exports = {
	serverConfig,
};
