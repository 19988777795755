import styled, { css } from 'styled-components';

export const StyledContent = styled.div`
	${(props) => (props.$open ? 'margin-bottom: 40px;' : 'max-height: 270px;')};
	float: left;
	overflow: ${(props) => (props.$open ? 'unset' : 'hidden')};
`;

export const StyledContentWrapper = styled.div`
	float: left;
	position: relative;

	&:after {
		${(props) =>
			props.$open
				? css`
						background: unset !important;
				  `
				: css`
						background: linear-gradient(
							180deg,
							transparent,
							${(props) => props.$shadowColor || '#051b36'} 90%
						);
				  `}
		bottom: -20px;
		content: '';
		display: block;
		left: -50vw;
		pointer-events: none;
		position: absolute;
		right: -50vw;
		top: 0;
	}

	button {
		bottom: 0;
		left: 50%;
		position: absolute;
		transform: translateX(-50%);
		z-index: 1;
	}

	.text-style-xs-regular {
		color: var(--color-dark-100);
	}

	.text-style-md-semibold {
		color: var(--color-dark-50);
	}

	h2 {
		border: unset;
		margin: 0 0 15px;
	}

	ul {
		width: 100%;
	}

	section {
		float: left;
	}
`;
