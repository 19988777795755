import {
	faArrowDown,
	faArrowLeft,
	faArrowRight,
	faArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { GameType } from '@modules/games/Game/constants/availableGames';

export const game_config: Record<
	GameType,
	{
		isAutobet: boolean;
		isFlashbet: boolean;
		isHighSpeedOption?: boolean;
		houseEdge?: number;
		maxPayout?: number;
		minPayout?: number;
		maxBetResult?: number;
		minBetResult?: number;
		betValue?: number;
		resultAnimation?: boolean;
		payout?: number;
		payoutDecimal?: number;
		winChanceDecimal?: number;
		oddsDecimal?: number;
		hotkeys?: {
			id: string;
			symbol?: string;
			icon?: any;
			keyCode: number;
			name: string;
			rule?: string[];
		}[];
	}
> = {
	// TODO: NEW GAME FLAG
	[GameType.DICE]: {
		isAutobet: true,
		isFlashbet: true,
		houseEdge: 1, // isRequired
		maxPayout: 9900, // isRequired
		minPayout: 1.0102, // isRequired
		maxBetResult: 99.99,
		minBetResult: 0,
		betValue: 50,
		resultAnimation: false,
		hotkeys: [
			{
				id: 'roll',
				symbol: 'SPACE',
				keyCode: 32,
				name: 'Place bet / Stop autobet',
			},
			{
				id: 'lower_slider',
				icon: faArrowLeft,
				keyCode: 37,
				name: 'Lower target',
				rule: ['over', 'under', 'direct'],
			},
			{
				id: 'higher_slider',
				icon: faArrowRight,
				keyCode: 39,
				name: 'Higher target',
				rule: ['over', 'under', 'direct'],
			},
			{
				id: 'switch_direction',
				symbol: 'R',
				keyCode: 82,
				name: 'Switch over/under',
				rule: ['over', 'under'],
			},
		],
	},
	[GameType.LIMBO]: {
		isAutobet: true,
		isFlashbet: true,
		houseEdge: 1, // isRequired
		maxPayout: 1000000, // isRequired
		minPayout: 1.01, // isRequired
		payout: 2,
		payoutDecimal: 2,
		winChanceDecimal: 8,
		resultAnimation: false,
		hotkeys: [
			{
				id: 'roll',
				symbol: 'SPACE',
				keyCode: 32,
				name: 'Place bet / Stop autobet',
			},
			{
				id: 'lower_target',
				icon: faArrowLeft,
				keyCode: 37,
				name: 'Lower target',
			},
			{
				id: 'higher_target',
				icon: faArrowRight,
				keyCode: 39,
				name: 'Higher target',
			},
		],
	},
	[GameType.PLINKO]: {
		isAutobet: true,
		isFlashbet: true,
		isHighSpeedOption: true,
		houseEdge: 1, // isRequired
		maxPayout: 2000, // isRequired
		minPayout: 0, // isRequired
		payout: null,
		payoutDecimal: 1,
		winChanceDecimal: 2,
		resultAnimation: true,
		hotkeys: [
			{
				id: 'roll',
				symbol: 'SPACE',
				keyCode: 32,
				name: 'Place bet / Stop autobet',
			},
		],
	},
	[GameType.HILO]: {
		isAutobet: false,
		isFlashbet: false,
		houseEdge: 1, // isRequired
		maxPayout: 9900, // isRequired
		minPayout: 1.0102, // isRequired
		resultAnimation: false,
		hotkeys: [
			{
				id: 'bet',
				symbol: 'SPACE',
				keyCode: 32,
				name: 'Place bet',
			},
			{
				id: 'cashout',
				symbol: 'ENTER',
				keyCode: 13,
				name: 'Cashout',
			},
			{
				id: 'draw',
				symbol: 'SHIFT',
				keyCode: 16,
				name: 'Skip current card',
			},
			{
				id: 'high',
				icon: faArrowUp,
				keyCode: 38,
				name: 'Higher or the same',
			},
			{
				id: 'low',
				icon: faArrowDown,
				keyCode: 40,
				name: 'Lower or the same',
			},
			{
				id: 'red',
				symbol: 'A',
				keyCode: 65,
				name: 'Red',
			},
			{
				id: 'black',
				symbol: 'S',
				keyCode: 83,
				name: 'Black',
			},
			{
				id: 'number',
				symbol: 'Z',
				keyCode: 90,
				name: '2-10',
			},
			{
				id: 'letter',
				symbol: 'X',
				keyCode: 88,
				name: 'JQKA',
			},
		],
	},
	[GameType.SPORTS]: {
		isAutobet: false,
		isFlashbet: false,
		houseEdge: 1, // isRequired
		maxPayout: 9900, // isRequired
		minPayout: 1.0102, // isRequired
		oddsDecimal: 2,
		payoutDecimal: 2,
	},
	[GameType.SLOTS]: {
		isAutobet: false,
		isFlashbet: false,
		payoutDecimal: 2,
	},
};
