import queryString from 'querystring';
import { combineEpics } from 'redux-observable';
import { delay } from 'rxjs/operators';
import { of } from 'rxjs';
import { TABLE_ID } from '@modules/transactions/constants/TableId';
import { parseTransactionsData } from '@modules/transactions/methods/parseTransactionsData';
import transactionService, {
	blockGetTransaction,
} from '../../../../modules/transactions/transactionsService';
import { config, FEATURES_TYPE } from '../../config';
import { getMyBets } from '../user/user.actions';
import { RESET_USER_DATA } from '../user/user.constants';
import { showAlert } from '../alerts/alerts.actions';
import websocketService from '../../modules/app/websocketService';
import {
	bindSocketPrivateChannelEvent,
	unbindSocketChannel,
} from '../websocket/websocket.actions';
import { depositEvent, withdrawEvent } from '../gtm/gtm.actions';
import { APP_CLOSES, TOGGLE_MAINTENANCE_MODE } from '../app/app.constants';
import { trans } from '../../modules/translation/translate';
import { capitalize } from '../../modules/app/appService';
import { epic } from '../../modules/app/epicService';
import { isFeatureAvailable } from '../../modules/app/featureService';
import { fetchApiAction } from '../fetch/fetch.thunk';
import {
	WEBSOCKET_CHANNELS,
	WEBSOCKET_EVENTS,
} from '../websocket/websocket.constants';
import { bindSocketChannelEventThunk } from '../websocket/thunks/bindSocketChannelEvent.thunk';
import { updateUrlQuery } from '../app/app.actions';
import {
	clearTransactions,
	getAllBets,
	getHighRolls,
	handleUpdateInterval,
	newBet,
	newDeposit,
	newDepositDetected,
	newHighRoll,
	pendingTransactions,
	resetTransactions,
	setAllBets,
	setHighRolls,
	setNewMyBets,
	transactionsActive,
	transactionsFulfilled,
	transactionsSet,
	transactionsSetMeta,
	transactionsUpdate,
	transactionsUpdating,
	withdrawConfirmed,
} from './transactions.actions';
import {
	CLEAR_TRANSACTIONS,
	GET_ALL_BETS,
	GET_HIGH_ROLLS,
	GET_TRANSACTIONS,
	HANDLE_UPDATE_INTERVAL,
	HANDLE_WITHDRAWS,
	NEW_BET,
	NEW_DEPOSIT,
	NEW_DEPOSIT_DETECTED,
	NEW_HIGH_ROLL,
	NEW_MY_BET,
	RESET_TRANSACTIONS,
	SET_NEW_MY_BETS,
	TRANSACTIONS_SET,
	TRANSACTIONS_UPDATE,
	WITHDRAW_CONFIRMED,
} from './transactions.constants';
import { createTableQueryPropertyKeyPage } from './methods/createTableQueryPropertyKey';
import { getTransactionsThunk } from './thunks/getTransactions.thunk';

const newDepositEpic = epic('newDepositEpic', {
	actions: (ofType) => ofType(NEW_DEPOSIT),
	callback: ({ action }) => {
		let result = [];
		const data = action.payload;
		if (data.currency && data.amount) {
			const currency = data.currency;
			result.push(
				getTransactionsThunk({
					id: TABLE_ID.depositHistory,
					refresh: true,
				}),
				showAlert(
					'success',
					trans({
						label: '{{amount}} {{currency}} has been credited to your account',
						options: {
							amount: data.amount,
							currency: currency.toUpperCase(),
						},
					}),
				),
			);
		}
		return of(result);
	},
});

const newDepositDetectedEpic = epic('newDepositDetectedEpic', {
	actions: (ofType) => ofType(NEW_DEPOSIT_DETECTED),
	callback: ({ action }) => {
		let result = [];
		const data = action.payload;
		if (data.currency && data.amount) {
			const currency = data.currency;
			result.push(
				showAlert(
					'success',
					trans({
						label:
							'Deposit of {{amount}} {{currency}} has been detected, waiting for the transaction to be confirmed',
						options: {
							amount: data.amount,
							currency: currency.toUpperCase(),
						},
					}),
				),
			);
		}
		return of(result);
	},
});

const depositEpic = epic('depositEpic', {
	actions: (ofType) => ofType(TRANSACTIONS_SET),
	callback: ({ action }) => {
		if (action.id !== TABLE_ID.depositHistory) {
			return of();
		}
		const result = [];
		action.payload
			.filter((el) => el.new)
			.forEach((el) =>
				result.push(
					depositEvent({
						...el,
					}),
				),
			);
		return of(result);
	},
});

const getAllBetsEpic = epic('getAllBetsEpic', {
	actions: (ofType) => ofType(GET_ALL_BETS),
	callback: ({ action }) => {
		const page = action.page || 1;
		return of(
			fetchApiAction(
				{
					url: `/bet/last?page=${page}`,
					loaderId: 'GetTransactions',
				},
				(data) => {
					if (data && data.data) {
						return [
							setAllBets(data.data),
							transactionsUpdating(TABLE_ID.allBets, true),
							bindSocketChannelEventThunk(
								WEBSOCKET_CHANNELS.ALL_BETS,
								WEBSOCKET_EVENTS.BET_RESULT,
								(data, dispatch) =>
									dispatch(
										pendingTransactions(
											TABLE_ID.allBets,
											websocketService.getMessage(data),
										),
									),
							),
							handleUpdateInterval(TABLE_ID.allBets),
						];
					}
					return [];
				},
			),
		);
	},
});

const getHighRollsEpic = epic('getHighRollsEpic', {
	actions: (ofType) => ofType(GET_HIGH_ROLLS),
	callback: ({ action }) => {
		const page = action.page || 1;
		return of(
			fetchApiAction(
				{
					url: `/bet/last/high?page=${page}`,
					loaderId: 'highGetTransactions',
				},
				(data) => {
					if (data?.data?.length) {
						return [
							setHighRolls(data.data),
							transactionsUpdating(TABLE_ID.highRolls, true),
							bindSocketChannelEventThunk(
								WEBSOCKET_CHANNELS.HIGH_ROLLS,
								WEBSOCKET_EVENTS.BET_RESULT,
								(data, dispatch) =>
									dispatch(newHighRoll(websocketService.getMessage(data))),
							),
						];
					}
					return [];
				},
			),
		);
	},
});

const fulfillEpic = epic('fulfillEpic', {
	actions: (ofType) =>
		ofType(
			SET_NEW_MY_BETS,
			NEW_BET,
			NEW_MY_BET,
			NEW_HIGH_ROLL,
			TRANSACTIONS_SET,
			TRANSACTIONS_UPDATE,
		),
	callback: ({ action, store$ }) => {
		if (action.reset) {
			return of();
		}
		const id = action.id || transactionService.getIdFromActionType(action.type);
		const list = action.payload;
		const result = [];
		if (
			action.type === NEW_MY_BET &&
			action.limit &&
			store$.value.transactions.fulfilled[id]
		) {
			result.push(transactionsFulfilled(id, false));
		} else if (
			(!list.length || list.length < config.transactionsPaginationLength) &&
			!store$.value.transactions.fulfilled[id]
		) {
			result.push(transactionsFulfilled(id, true));
		}
		return of(result);
	},
});

const withdrawEpic = epic('withdrawEpic', {
	actions: (ofType) => ofType(TRANSACTIONS_UPDATE),
	callback: ({ action }) => {
		if (action.id !== TABLE_ID.withdrawHistory) {
			return of();
		}
		return of(
			withdrawEvent({
				...action.payload,
				new: true,
			}),
		);
	},
});

const resetEpic = epic('resetEpic', {
	actions: (ofType) => ofType(RESET_TRANSACTIONS),
	callback: ({ action, store$ }) => {
		switch (action.id) {
			case TABLE_ID.allBets: {
				return of(setAllBets([], true));
			}
			case TABLE_ID.highRolls: {
				return of(setHighRolls([], true));
			}
			case TABLE_ID.myBets: {
				transactionService.saveMyBetsToMemory(
					store$.value.transactions[TABLE_ID.myBets],
					store$.value.user.id,
				);
				return of(setNewMyBets([], true));
			}
			default: {
				if (
					store$.value.transactions[action.id] &&
					store$.value.transactions[action.id].length
				) {
					return of(transactionsSet(action.id, [], true));
				}
				return of();
			}
		}
	},
});

const clearEpic = epic('clearEpic', {
	actions: (ofType) => ofType(CLEAR_TRANSACTIONS),
	callback: ({ action, store$ }) => {
		if (
			store$.value.transactions.active[action.id] ||
			!store$.value.transactions.update[action.id]
		) {
			return of();
		}
		// TODO: delete below line when myBets from backend will be always updated
		if (action.id === WEBSOCKET_EVENTS.MY_BETS) {
			return of();
		}
		// unbindSocketPrivateChannelEvent(action.id)
		return of([
			updateUrlQuery({
				[createTableQueryPropertyKeyPage(action.id)]: undefined,
			}),
			unbindSocketChannel(action.id),
			resetTransactions(action.id),
			transactionsFulfilled(action.id, false),
		]);
	},
});

const getDataEpic = epic('getDataEpic', {
	actions: (ofType) => ofType(GET_TRANSACTIONS),
	callback: ({ action, store$ }) => {
		// console.log('GET_TRANSACTIONS', {
		//   ...action,
		//   fullfilled: store$.value.transactions.fulfilled[action.id],
		//   update: store$.value.transactions.update[action.id],
		//   isUpdate: !action.disable === store$.value.transactions.update[action.id],
		//   isFullFilled: store$.value.transactions.fulfilled[action.id] && !action.disable,
		//   allow: !blockGetTransaction(action, store$.value),
		// });
		if (blockGetTransaction(action, store$.value)) {
			return of();
		}

		const query = queryString.parse(
			store$.value.router.location.search.replace('?', ''),
		);
		const tablePageKey = createTableQueryPropertyKeyPage(action.id);
		const pageFromQuery = Number(query[tablePageKey]);

		const page = action.page ? action.page : pageFromQuery || 1;
		const uri = transactionService.getTransactionsUri({
			...action,
			page,
		});

		if (!uri) {
			return of();
		}
		// console.log(action.id, {
		//   uri,
		//   action,
		//   page,
		// });
		return of(
			fetchApiAction(
				{
					url: uri,
					loaderId: `get${capitalize(action.id)}`,
					disableErrorHandler: action.refresh,
				},
				(data) => {
					if (!data) {
						return [];
					}
					const { data: transactions, meta } = parseTransactionsData({
						id: action.id,
						data,
					});
					if (!transactions) {
						return [];
					}
					const result = [transactionsUpdating(action.id, true)];
					if (meta) {
						if (pageFromQuery || meta.current_page > 1) {
							result.push(
								updateUrlQuery({
									[tablePageKey]:
										meta.current_page > 1 ? meta.current_page : null,
								}),
							);
						}

						result.push(transactionsSetMeta(action.id, meta));
					}
					if (!page) {
						result.push(transactionsFulfilled(action.id, false));
					}
					result.push(transactionsSet(action.id, transactions));
					return result;
				},
			),
		);
	},
});

const handleEpic = epic('handleEpic', {
	actions: (ofType) => ofType(GET_TRANSACTIONS),
	callback: ({ action, store$ }) => {
		if (blockGetTransaction(action, store$.value)) {
			return of();
		}

		const result = [];

		if (action.id === TABLE_ID.myBets) {
			result.push(getMyBets(action.page, true));
		}
		if (action.id === TABLE_ID.allBets) {
			result.push(getAllBets(action.page));
		}
		if (action.id === TABLE_ID.highRolls) {
			result.push(getHighRolls(action.page));
		}
		if (action.id === TABLE_ID.depositHistory && !action.disable) {
			result.push(
				bindSocketPrivateChannelEvent(
					WEBSOCKET_EVENTS.WALLET_DEPOSIT_ACCOUNTED,
					(data, dispatch) =>
						dispatch(
							newDeposit({
								...websocketService.getMessage(data),
								unread: true,
							}),
						),
				),
				bindSocketPrivateChannelEvent(
					WEBSOCKET_EVENTS.WALLET_DEPOSIT_DETECTED,
					(data, dispatch) =>
						dispatch(newDepositDetected(websocketService.getMessage(data))),
				),
			);
		}
		return of(result);
	},
});

const clearDelayEpic = epic('clearDelayEpic', {
	actions: (ofType) => ofType(GET_TRANSACTIONS),
	callback: ({ action, store$ }) => {
		if (
			!action.page &&
			!action.refresh &&
			((store$.value.transactions.fulfilled[action.id] && !action.disable) ||
				!action.disable === store$.value.transactions.update[action.id])
		) {
			return of();
		}

		if (
			[
				TABLE_ID.myBets,
				TABLE_ID.allBets,
				TABLE_ID.highRolls,
				TABLE_ID.sessionHistory,
				TABLE_ID.affiliateCampaigns,
				TABLE_ID.affiliateCashoutHistory,
			].indexOf(action.id) > -1 &&
			action.disable
		) {
			return of(clearTransactions(action.id)).pipe(
				delay(config.transactionsClearTimeout),
			);
		}

		return of();
	},
});

const pendingEpic = epic('pendingEpic', {
	actions: (ofType) => ofType(HANDLE_UPDATE_INTERVAL),
	callback: ({ action, store$ }) => {
		if (
			!store$.value.transactions.update[action.id] ||
			!isFeatureAvailable(FEATURES_TYPE.PENDING_TRANSACTIONS)
		) {
			return of();
		}
		return of(handleUpdateInterval(action.id)).pipe(
			delay(config.delayTransactionsUpdate),
		);
	},
});

const pendingDataEpic = epic('pendingDataEpic', {
	actions: (ofType) => ofType(HANDLE_UPDATE_INTERVAL),
	callback: ({ action, store$ }) => {
		if (!store$.value.transactions.update[action.id]) {
			return of();
		}

		const bet = transactionService.getBetFromPending(
			store$.value.transactions.pending[action.id],
		);

		if (!bet) {
			return of();
		}

		if (action.id === TABLE_ID.allBets) {
			return of(newBet(bet));
		}

		return of();
	},
});

const activeEpic = epic('activeEpic', {
	actions: (ofType) => ofType(GET_TRANSACTIONS),
	callback: ({ action }) => {
		return of(transactionsActive(action.id, !action.disable));
	},
});

const handleWithdrawsEpic = epic('handleWithdrawsEpic', {
	actions: (ofType) => ofType(HANDLE_WITHDRAWS),
	callback: () => {
		return of(
			bindSocketPrivateChannelEvent(
				WEBSOCKET_EVENTS.WALLET_WITHDRAW_ACCEPTED,
				(data, dispatch) =>
					dispatch(withdrawConfirmed(websocketService.getMessage(data))),
			),
		);
	},
});

const handleWithdrawConfirmedEpic = epic('handleWithdrawConfirmedEpic', {
	actions: (ofType) => ofType(WITHDRAW_CONFIRMED),
	callback: ({ action, store$ }) => {
		const list = store$.value.transactions[TABLE_ID.withdrawHistory];
		const result = [
			showAlert(
				'success',
				trans({
					label: 'Withdraw of {{amount}} {{currency}} has been processed',
					options: {
						amount: action.payload.amount,
						currency: action.payload.currency,
					},
				}),
			),
		];
		if (!list.length) {
			return of(result);
		}
		return of([
			...result,
			transactionsUpdate(
				TABLE_ID.withdrawHistory,
				list.map((el) => {
					if (el.uuid !== action.payload.uuid) {
						return el;
					}
					return {
						...el,
						...action.payload,
					};
				}),
			),
		]);
	},
});

const appClosesEpic = epic('appClosesEpic', {
	actions: (ofType) => ofType(APP_CLOSES),
	callback: ({ store$ }) => {
		if (store$.value.transactions[TABLE_ID.myBets].length) {
			transactionService.saveMyBetsToMemory(
				store$.value.transactions[TABLE_ID.myBets],
				store$.value.user.id,
			);
		}
		return of();
	},
});

const disabledMaintenanceEpic = epic('disabledMaintenanceEpic', {
	actions: (ofType) => ofType(TOGGLE_MAINTENANCE_MODE),
	callback: ({ action, store$ }) => {
		const result = [];
		transactionService.saveMyBetsToMemory();
		if (store$.value.transactions[TABLE_ID.myBets].length) {
			result.push(transactionsSet(TABLE_ID.myBets, [], true));
		}
		if (!action.payload && store$.value.transactions.update[TABLE_ID.myBets]) {
			result.push(getMyBets(1, true));
		}
		return of(result);
	},
});

const clearMyBetsFromMemory = epic('clearMyBetsFromMemory', {
	actions: (ofType) => ofType(RESET_USER_DATA),
	callback: () => {
		transactionService.saveMyBetsToMemory();
		return of();
	},
});

const transactionsEpic = combineEpics(
	clearMyBetsFromMemory,
	disabledMaintenanceEpic,
	clearDelayEpic,
	newDepositEpic,
	appClosesEpic,
	depositEpic,
	resetEpic,
	fulfillEpic,
	withdrawEpic,
	getDataEpic,
	clearEpic,
	getAllBetsEpic,
	getHighRollsEpic,
	activeEpic,
	newDepositDetectedEpic,
	pendingEpic,
	pendingDataEpic,
	handleWithdrawsEpic,
	handleWithdrawConfirmedEpic,
	handleEpic,
);

export { transactionsEpic };
