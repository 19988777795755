import styled from 'styled-components';
import classnames from 'classnames';

interface BalanceCurrencyWrapperStyleProps {
	$isCurrencyInFront?: boolean;
	$isCurrency?: boolean;
}

export const BalanceCurrencyWrapperStyle = styled.span.attrs<BalanceCurrencyWrapperStyleProps>(
	(props) => ({
		className: classnames('balance__currency--wrapper', props.className),
	}),
)<BalanceCurrencyWrapperStyleProps>`
	display: flex;
	align-items: center;
	justify-content: ${(props) =>
		props.$isCurrencyInFront ? 'flex-start' : 'flex-end'};
	gap: 5px;
	flex-direction: ${(props) =>
		props.$isCurrencyInFront ? 'row' : 'row-reverse'};
`;
