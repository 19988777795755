import { TableIdType } from '@modules/transactions/constants/TableId';
import { getTransactions } from '../transactions.actions';

export interface GetTransactionsThunkProps {
	id: TableIdType;
	page?: number;
	disable?: boolean;
	refresh?: boolean;
	props?: any;
}

export const getTransactionsThunk = ({
	id,
	page,
	disable,
	refresh,
	props,
}: GetTransactionsThunkProps) => {
	return (dispatch) => {
		dispatch(getTransactions(id, page, disable, refresh, props));
	};
};
