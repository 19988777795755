import { FC } from 'react';
import Icon from '../../../../common/components/icons/Icon';
import {
	CurrencyTokenType,
	CurrencyType,
} from '../../../types/wallet/CurrencyType';
import { CurrencyLogoStyle } from './currencySymbol.style';

export const TokenSymbol: FC<{
	token: CurrencyType | CurrencyTokenType;
	classes?: string;
}> = ({ token, classes }) => {
	if (!token) {
		return null;
	}
	return (
		<CurrencyLogoStyle data-token={token}>
			<Icon data-name={token} className={classes} id={`currencies/${token}`} />
		</CurrencyLogoStyle>
	);
};
