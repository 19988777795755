import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { languageConfig } from '@server/config/language.config';
import { RootState } from '@legacyApp/client/store/configure.store';
import { DeviceType } from '../methods/getDeviceType';

export const useAppVisible = () =>
	useAppSelector<boolean>((state) => state.app.visible, shallowEqual);
export const useAppDeviceType = () =>
	useAppSelector<DeviceType>((state) => state.app.deviceType, shallowEqual);
export const useContentDeviceType = () =>
	useAppSelector((state) => state.app.contentDeviceType, shallowEqual);
export const useAppMobile = () =>
	useAppSelector<boolean>((state) => state.app.mobile, shallowEqual);
export const useAppLanguage = () =>
	useAppSelector<string>(
		(state) => state.app.language || languageConfig.default,
		shallowEqual,
	);
export const useAppNoDefaultLanguage = () =>
	useAppSelector<string>((state) => state.app.language, shallowEqual);
export const useAppInitialized = () =>
	useAppSelector<boolean>((state) => state.app.initialized, shallowEqual);
export const useAppIsViewLocked = () =>
	useAppSelector<RootState['app']['isViewLocked']>(
		(state) => state.app.isViewLocked,
		shallowEqual,
	);
export const useAppCsrf = () =>
	useAppSelector((state) => state.app.csrf, shallowEqual);
export const useAppIsMenuVisible = () =>
	useAppSelector<boolean>((state) => state.app.isMenuVisible, shallowEqual);
export const useAppIsMaintenanceMode = () =>
	useAppSelector<boolean>((state) => state.app.maintenanceMode, shallowEqual);
export const useAppHeight = () =>
	useAppSelector<RootState['app']['height']>(
		(state) => state.app.height,
		shallowEqual,
	);
export const useAppWidth = () =>
	useAppSelector<RootState['app']['width']>(
		(state) => state.app.width,
		shallowEqual,
	);
