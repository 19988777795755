import { shallowEqual } from 'react-redux';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';
import { CurrencyType } from '@legacyApp/types/wallet/CurrencyType';
import { AvailableBonusCode } from '@legacyApp/client/store/user/user.interfaces';
import { RaceBadgeType } from '@legacyApp/types/user/UserType';
import transactionService from '../../modules/transactions/transactionsService';

export const useUserIsLogged = () =>
	useAppSelector<boolean>((state) => state.user.isLogged, shallowEqual);
export const useUserName = () =>
	useAppSelector<string>((state) => state.user.name, shallowEqual);
export const useUserId = () =>
	useAppSelector<string>((state) => state.user.id, shallowEqual);
export const useUserRaceBadge = () =>
	useAppSelector<RaceBadgeType>((state) => state.user.raceBadge, shallowEqual);
export const useUserActiveCurrency = () =>
	useAppSelector<CurrencyType>(
		(state) => state.user.activeCurrency,
		shallowEqual,
	);
export const useUserTokenValue = () =>
	useAppSelector<string>((state) => state.user.token?.value, shallowEqual);
export const useUserKycToVerify = () =>
	useAppSelector<boolean>((state) => state.user.hasKycToVerify, shallowEqual);
export const useUserEmailToVerify = () =>
	useAppSelector<boolean>((state) => state.user.hasEmailToVerify, shallowEqual);
export const useUserEmail = () =>
	useAppSelector<string>((state) => state.user.email, shallowEqual);
export const useUserWithdrawBlocked = () =>
	useAppSelector<boolean>((state) => state.user.withdrawBlocked, shallowEqual);
export const useUserWithdrawBlockedTimestamp = () =>
	useAppSelector<number>(
		(state) => state.user.withdrawBlockedTimestamp,
		shallowEqual,
	);
export const useUserIsAdmin = () =>
	useAppSelector<boolean>((state) => state.user.isAdmin, shallowEqual);
export const useUserIsModerator = () =>
	useAppSelector<boolean>((state) => state.user.isModerator, shallowEqual);
export const useUserIsHighRoller = () =>
	useAppSelector<boolean>((state) => state.user.isHighRoller, shallowEqual);
export const useUserJoinedAt = () =>
	useAppSelector<boolean>((state) => state.user.joinedDate, shallowEqual);

type UseAvailableBonusCode = () => AvailableBonusCode | null;
export const useUserHasAvailableBonusCode: UseAvailableBonusCode = () =>
	useAppSelector(
		(state) => state.user.hasAvailableBonusCode,
		(prev, next) =>
			transactionService.isDuplicate(prev, next, [
				'amount',
				'code',
				'currency',
				'created_at',
			]),
	);
