import { objectBoolReducer } from '../../modules/app/reducers/objectBoolReducer';
import { OPTIONS, OPTIONS_DISABLE, OPTIONS_ENABLE } from './options.constants';

const optionsReducer = (data) =>
	objectBoolReducer(
		{
			[OPTIONS.AUTOBET_CHART]: true,
			[OPTIONS.ANIMATION]: true,
			[OPTIONS.AUTOBET_SUMMARY]: true,
			[OPTIONS.SOUND]: true,
			...(data || {}),
		},
		OPTIONS_ENABLE,
		OPTIONS_DISABLE,
	);

export { optionsReducer };
