import { createContext } from 'react';

export interface SideMenuContextProps {
	isOpen?: boolean;
	setIsOpen: (isOpen: boolean) => void;
}

export const SideMenuContext = createContext<SideMenuContextProps>({
	isOpen: null,
	setIsOpen: null,
});
