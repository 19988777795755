import Redux, { combineReducers } from 'redux';
import { RESET_USER_DATA } from '@legacyApp/client/store/user/user.constants';
import { BalancesArray } from '@legacyApp/types/balance/BalancesArray';
import { Balances } from '@legacyApp/types/balance/Balances';
import {
	currenciesSettings,
	DEFAULT_CURRENCY,
} from '@common/controllers/currency/currenciesSettings';
import { DIRECTION } from '@legacyApp/client/modules/app/sortService';
import {
	BALANCE_SET_CONVERTED,
	BalanceConvertedCurrencies,
	SET_BALANCES_ARRAY,
	UPDATE_BALANCE,
} from './balance.constants';

export interface BalanceReducerType {
	balancesArray: BalancesArray;
	balances: Balances;
	balanceConverted?: BalanceConvertedCurrencies;
}

const balancesArrayReducer = (
	state: BalanceReducerType['balancesArray'] = [
		{ currency: DEFAULT_CURRENCY, amount: 0, amount_usd: 0 },
	],
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_BALANCES_ARRAY:
			return action.payload.sort((a, b) =>
				currenciesSettings.sortCurrency(
					DIRECTION.ASC,
					a.currency.toLowerCase(),
					b.currency.toLowerCase(),
				),
			);
		case RESET_USER_DATA:
			return [];
		default:
			return state;
	}
};

const balancesReducer = (
	state: BalanceReducerType['balances'] = {
		[DEFAULT_CURRENCY]: 0,
	},
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	let newState;
	switch (action.type) {
		case UPDATE_BALANCE:
			newState = {
				...state,
			};
			if (
				typeof action.payload === 'object' &&
				action.payload.length !== undefined
			) {
				action.payload.forEach((el) => {
					newState[el.currency] = el.amount;
				});
			} else if (typeof action.payload === 'object' && !action.payload.amount) {
				newState = {
					...newState,
					...action.payload,
				};
			} else if (
				typeof action.payload === 'object' &&
				action.payload.amount &&
				action.payload.currency
			) {
				newState[action.payload.currency] = action.payload.amount;
			}
			return newState;
		case RESET_USER_DATA:
			return {};
		default:
			return state;
	}
};

const balanceConvertedReducer = (
	state: BalanceReducerType['balanceConverted'] = null,
	action: Redux.AnyAction = {
		type: '',
	},
) => {
	switch (action.type) {
		case BALANCE_SET_CONVERTED:
			return action.payload;
		default:
			return state;
	}
};

const balanceReducer = combineReducers({
	balancesArray: balancesArrayReducer,
	balances: balancesReducer,
	balanceConverted: balanceConvertedReducer,
});

export { balanceReducer };
