import { useCallback, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import classnames from 'classnames';
import { useSideMenu } from '@modules/SideMenu/hooks/useSideMenu';
import { useModalIsOpened } from '@modules/modals/store/modal.selectors';
import {
	appCloses,
	listenCommands,
	setDeviceType,
	setHeight,
	setMobile,
	setMobileChat,
	setVisible,
	setWidth,
} from '@legacyApp/client/store/app/app.actions';
import { pwaInit } from '@legacyApp/client/store/pwa/pwa.actions';
import {
	checkIsMobile,
	checkIsMobileChat,
} from '@legacyApp/client/modules/app/appService';
import { isWindowVisible } from '@legacyApp/methods/app/isWindowVisible';
import { isFeatureAvailable } from '@legacyApp/client/modules/app/featureService';
import { FEATURES_TYPE } from '@legacyApp/client/config';
import { useGameActiveGame } from '@modules/games/Game/store/game/game.selectors';
import { useChatVisible } from '@modules/chat/store/selectors/useChatVisible';
import { getClientSize } from '@common/methods/getClientSize';
import {
	useDocumentEventListener,
	useWindowEventListener,
} from '../../hooks/useEventListener';
import { EnhancedNextComponentPageProps } from '../../hoc/pageEnhancer';
import { useOnMount } from '../../hooks/useOnMount';
import { getDeviceType } from '../../methods/getDeviceType';
import { useAuthInitialized } from '../../hooks/useAuthInitialized';
import { useRouterQueryTab } from '../../selectors/router.selectors';
import {
	useAppDeviceType,
	useAppInitialized,
	useAppIsViewLocked,
	useAppLanguage,
	useAppMobile,
	useContentDeviceType,
} from '../../selectors/app.selectors';
import { FullscreenLoadingContext } from '../../context/app/FullscreenLoading.context';

const RESIZE_REPEAT_LIMIT = 10;
let width, height, timeout;

export const useApp = (opts: EnhancedNextComponentPageProps) => {
	const { props, id } = opts;
	const { setLoaded } = useContext(FullscreenLoadingContext);
	const [rootClasses, setRootClasses] = useState<string>('');

	const dispatch = useDispatch();

	const { isOpen: isLeftMenuOpen } = useSideMenu();

	const isMobile = useAppMobile();
	const game = useGameActiveGame();
	const language = useAppLanguage();
	const isViewLocked = useAppIsViewLocked();
	const isChatVisible = useChatVisible();
	const modalOpened = useModalIsOpened();
	const tab = useRouterQueryTab();
	const deviceType = useAppDeviceType();
	const isAppInitialized = useAppInitialized();
	const contentDeviceType = useContentDeviceType();
	const { isInitialized: isAuthInitialized } = useAuthInitialized({ id });

	const listenCommands_ = () => dispatch(listenCommands());
	const pwaInit_ = () => dispatch(pwaInit());

	const getRootClasses = useCallback(() => {
		return classnames(
			`language-${language}`,
			`tab-${tab}`,
			`device-${deviceType}`,
			`content-${contentDeviceType}`,
		);
	}, [contentDeviceType, deviceType, language, tab]);

	const isMobileChat_ = useCallback(() => {
		return isChatVisible ? checkIsMobileChat() : checkIsMobile();
	}, [isChatVisible]);

	const handleResize = useCallback(
		(counter = 0) => {
			const { width: currentWidth, height: currentHeight } = getClientSize();
			if (width === null) {
				if (counter < RESIZE_REPEAT_LIMIT) {
					timeout = setTimeout(() => {
						handleResize(counter + 1);
					}, 1000);
				}
				return;
			}
			document.documentElement.style.setProperty(
				'--vh',
				`${currentHeight * 0.01}px`,
			);
			if (currentWidth !== width) {
				width = currentWidth;
				dispatch(setWidth(currentWidth));
				dispatch(setMobile(checkIsMobile()));
				dispatch(setDeviceType(getDeviceType()));
				dispatch(setMobileChat(isMobileChat_()));
			}
			if (currentHeight !== height) {
				height = currentHeight;
				dispatch(setHeight(currentHeight));
			}
			// if (!currentHeight || !currentWidth) {
			// 	sentryError(new Error('Invalid page size'), {
			// 		currentWidth,
			// 		currentHeight,
			// 		width,
			// 		height,
			// 		document,
			// 	});
			// }
		},
		[dispatch, isMobileChat_],
	);

	useWindowEventListener('resize', () => handleResize());
	useDocumentEventListener('visibilitychange', () => {
		dispatch(setVisible(isWindowVisible()));
	});
	useDocumentEventListener('mousewheel', () => {
		if (
			'type' in document.activeElement &&
			'blur' in document.activeElement &&
			document.activeElement.type === 'number'
		) {
			if (typeof document.activeElement.blur === 'function') {
				document.activeElement.blur();
			}
		}
	});
	useWindowEventListener('beforeunload', () => {
		dispatch(appCloses());
	});

	useOnMount(() => {
		if (isAppInitialized) {
			return;
		}
		pwaInit_();
		handleResize();
		setRootClasses(getRootClasses());
		dispatch(setMobile(checkIsMobile()));
		dispatch(setDeviceType(getDeviceType()));
		dispatch(setMobileChat(isMobileChat_()));
		listenCommands_();

		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	});

	useEffect(() => {
		document.body.style.cursor = isMobile ? 'pointer' : 'default';
	}, [isMobile]);

	useEffect(() => {
		dispatch(setMobileChat(isMobileChat_()));
	}, [dispatch, isMobileChat_]);

	useEffect(() => {
		const currClasses = getRootClasses();
		if (currClasses !== rootClasses) {
			setRootClasses(currClasses);
		}
	}, [getRootClasses, rootClasses]);

	useEffect(() => {
		setLoaded(isAuthInitialized);
	}, [isAuthInitialized, setLoaded]);

	return classnames(
		{
			'no-scroll': modalOpened,
			'locked-view': isViewLocked,
			christmas: isFeatureAvailable(FEATURES_TYPE.CHRISTMAS),
			'dark-theme': isFeatureAvailable(FEATURES_TYPE.DARK),
		},
		`left-menu-${isLeftMenuOpen ? 'visible' : 'hidden'}`,
		`chat-${isChatVisible && !props?.noChat ? 'visible' : 'hidden'}`,
		`page-${id}`,
		game,
		rootClasses,
	);
};
