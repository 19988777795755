const { updateFromLsConfig } = require('./updateFromLsConfig');
const apiUrl = process.env.API_URL || 'https://wolf.bet/api';

const apiConfig = updateFromLsConfig({
	apiUrl,
	sportsUrl: process.env.SPORTS_URL || apiUrl,
	autobetUrl: process.env.AUTOBET_URL || apiUrl,
	slotsUrl: process.env.SLOTS_URL || apiUrl,
	diceUrl: process.env.DICE_URL || apiUrl,
	sportsIframeUrl: process.env.SPORTS_IFRAME_URL || 'https://sports.wolf.bet',
});

module.exports = {
	apiConfig,
};
