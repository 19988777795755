import React, { FC } from 'react';
import { Carousel } from '@ui/carousel';
import { second } from '@legacyApp/client/modules/app/time';
import { TokenNames } from '../../constants/currency/settings/tokenNames';
import { NoFooterLogoTokens } from '../../constants/currency/settings/noFooterLogoTokens';
import { Image } from '../default/Image/Image';
import { useAppMobile } from '../../selectors/app.selectors';
import { CurrencyIcon } from './CurrencyIcon.styled';

export const CURRENCIES_CAROUSEL_LIST = Object.keys(TokenNames)
	.filter((token) => !NoFooterLogoTokens[token])
	.map((token) => ({
		id: token,
		url: `/img/currencies/${token}.svg`,
	}))
	.map((el) => ({
		id: el.id,
		content: (
			<CurrencyIcon>
				<Image key={el.id} src={el.url} alt={el.id} />
			</CurrencyIcon>
		),
	}));

export const CurrenciesCarousel: FC = () => {
	const isMobile = useAppMobile();
	return (
		<Carousel
			slidesToShow={isMobile ? 2 : 4}
			autoplay={true}
			autoplayInterval={3 * second}
			dragging={false}
			enableKeyboardControls={false}
			swiping={false}
			withoutControls={true}
			wrapAround={true}
			list={CURRENCIES_CAROUSEL_LIST}
		/>
	);
};
