import { css } from 'styled-components';
import { InputStyleType } from '@uiTypes/InputStyleType';
import { ButtonStyledProps } from '../Button.styled';

export const ButtonBorderRadius = (props: ButtonStyledProps) => {
	if (props.$border === InputStyleType.SQUARE) {
		return css`
			border-radius: 3px;
		`;
	}

	return css`
		border-radius: ${props.$isLabel ? '40px' : '100%'};
	`;
};
