import { css } from 'styled-components';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { ButtonStyledProps } from '../Button.styled';

export const ButtonColor = (props: ButtonStyledProps) => {
	if (props.$type === ButtonStyleType.LABEL) {
		if (props.disabled) {
			return css`
				color: var(--color-blue-dark);
			`;
		}

		return css`
			color: var(--color-blue-blue-light);
		`;
	}

	if (props.disabled) {
		return css`
			color: var(--color-white-white-50);
		`;
	}

	return css`
		color: var(--color-white);
	`;
};
