import Redux from 'redux';
import { actionSimple } from '@legacyApp/client/modules/app/actionService';

import { PayloadActionCall } from '@legacyApp/client/store/store.types';
import { QUERY_MODAL_CONSTANTS } from '@common/constants/modal/query.modal.constants';
import {
	CLOSE_MODAL,
	CLOSE_MODAL_DELAYED,
	MODAL_CLOSE_INFO,
	MODAL_HANDLE_PREVIOUS,
	MODAL_OPEN_INFO,
	MODAL_SET_PREVIOUS,
	MODAL_SET_PREVIOUS_PROPS,
	MODAL_SET_PROPS,
	MODAL_UPDATE_PROPS,
	OPEN_MODAL,
	OPEN_SECURITY_WARNING_MODAL,
	RESET_MODAL,
	SET_MODAL_CLOSE_CALLBACK,
	SET_MODAL_SOURCE,
} from './modal.constants';
import { ModalReducerType } from './modal.types';

export interface OpenModalResult extends Redux.Action {
	name?: ModalReducerType['id'];
	source?: ModalReducerType['source'];
	props?: ModalReducerType['props'];
	noClose?: boolean;
}

export const openModal = (
	name: ModalReducerType['id'],
	source: ModalReducerType['source'],
	props?: ModalReducerType['props'],
	noClose?: boolean,
) => {
	return {
		type: OPEN_MODAL,
		name,
		source,
		props,
		noClose,
	};
};

export const setModalSource: PayloadActionCall<ModalReducerType['source']> = (
	payload,
) => {
	return {
		type: SET_MODAL_SOURCE,
		payload,
	};
};

export const closeModal = actionSimple(CLOSE_MODAL);

export const resetModal = (force?: boolean, source?: string) => {
	return {
		type: RESET_MODAL,
		force,
		source,
	};
};

export interface SetModalCloseCallbackResult extends Redux.Action {
	callback?: ModalReducerType['closeCallback'];
	callbackData?: ModalReducerType['closeData'];
}

export const setModalCloseCallback = (
	callback: ModalReducerType['closeCallback'],
	callbackData?: ModalReducerType['closeData'],
) => ({
	type: SET_MODAL_CLOSE_CALLBACK,
	callback,
	callbackData,
});

export const closeModalDelayed = (delay?: number | boolean) => ({
	type: CLOSE_MODAL_DELAYED,
	delay,
});

export const openSecurityWarningModal = () => ({
	type: OPEN_SECURITY_WARNING_MODAL,
});

export const modalSetPrevious: PayloadActionCall<
	ModalReducerType['previous']
> = (payload) => ({
	type: MODAL_SET_PREVIOUS,
	payload,
});

export const modalHandlePrevious = actionSimple(MODAL_HANDLE_PREVIOUS);

export const modalSetProps: PayloadActionCall<ModalReducerType['props']> =
	actionSimple(MODAL_SET_PROPS);

export const modalUpdateProps: PayloadActionCall<ModalReducerType['props']> =
	actionSimple(MODAL_UPDATE_PROPS);

export const modalSetPreviousProps: PayloadActionCall<
	ModalReducerType['previous']
> = actionSimple(MODAL_SET_PREVIOUS_PROPS);

export const modalOpenInfo = actionSimple(MODAL_OPEN_INFO);

export const modalCloseInfo = actionSimple(MODAL_CLOSE_INFO);

export const isInitModal = (name) => QUERY_MODAL_CONSTANTS.indexOf(name) > -1;
