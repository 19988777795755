import { useRouter } from 'next/router';
import { NextSeo } from 'next-seo';
import { useCallback, useEffect } from 'react';

import { parseImageSrc } from '@common/components/default/Image/Image';
import { useStateEffect } from '@common/hooks/useStateEffect';
import { config } from '@legacyApp/client/config';
import { useMeta } from '@legacyApp/hooks/seo/useMeta';
import { DEFAULT_NAMESPACE } from '@legacyApp/client/modules/translation/translate.constants';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { languageConfig } from '@server/config/language.config';
import { getIndexingProperty } from '@common/components/default/Seo/methods/getIndexingProperty';

const Seo = ({
	titleTemplate,
	title,
	description,
	props,
	titleProps,
	descriptionProps,
	namespace = DEFAULT_NAMESPACE,
}: {
	titleTemplate?: string;
	title?: string;
	description?: string;
	props?: NextSeo['props'];
	titleProps?: any;
	descriptionProps?: any;
	namespace?: TransNamespace;
}) => {
	const { asPath, locale } = useRouter();

	const path = useStateEffect(
		useCallback(() => {
			const [pathname] = asPath.split('?');
			return `https://${config.domain}${
				locale === languageConfig.default || pathname.indexOf(locale) > -1
					? ''
					: `/${locale}`
			}${pathname === '/' ? '' : pathname}`;
		}, [asPath, locale]),
	);

	const meta = useMeta({
		title,
		titleTemplate,
		description,
		titleProps,
		descriptionProps,
		namespace,
	});

	useEffect(() => {
		// @ts-expect-error invalid type
		if (process.browser && window.wolfMetaTItle !== meta.title) {
			// @ts-expect-error invalid type
			window.wolfMetaTItle = meta.title;
		}
	}, [meta.title]);

	return (
		<NextSeo
			title={meta.title}
			description={meta.description}
			canonical={path}
			openGraph={{
				title: meta.title,
				description: meta.description,
				site_name: meta.title,
				type: 'website',
				locale,
				url: path,
				images: [
					{
						url: parseImageSrc('/img/og/social-image.webp'),
						width: 1500,
						height: 500,
					},
				],
			}}
			twitter={{
				handle: '@wolfdotbet',
				site: '@wolfdotbet',
				cardType: 'summary_large_image',
			}}
			{...props}
			nofollow={getIndexingProperty(props?.nofollow)}
			noindex={getIndexingProperty(props?.noindex)}
			disableGooglebot
		/>
	);
};

export default Seo;
