import { FC } from 'react';
import styled, { css } from 'styled-components';
import { trans } from '@legacyApp/client/modules/translation/translate';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { NEXT_CONFIG } from '../../constants/config/nextConfig';
import { useAppIsViewLocked } from '../../selectors/app.selectors';
import { FooterTextStyle } from './Footer.styled';
import { FooterTopRow } from './FooterTopRow';
import { FooterBottomRow } from './FooterBottomRow';

// @ts-expect-error invalid type
const BRANCH_NAME = NEXT_CONFIG.GIT_INFO?.branch;

export const Footer: FC = () => {
	const isLockedView = useAppIsViewLocked();

	if (isLockedView) {
		return null;
	}

	return (
		<FooterStyle>
			<FooterTopRow />
			<FooterBottomRow />
			<StyledFooterVersion className="text-style-caption-regular">
				{trans({ label: 'Version' })}: {BRANCH_NAME || ''}_
				{NEXT_CONFIG.RELEASE_VERSION}
			</StyledFooterVersion>
		</FooterStyle>
	);
};

export const FooterStyle = styled.footer`
	align-items: center;
	background-color: var(--color-dark-900);
	display: flex;
	flex-direction: column;
	float: left;
	height: auto;
	justify-content: center;
	padding: 64px 0;
	position: relative;
	width: 100%;
	z-index: 2;

	${chatViewport(
		(viewportPadding) => css`
			@media (max-width: ${(props) =>
					calcStyle({
						a: props.theme.media_v2.max_mobile_medium,
						b: viewportPadding,
					})}) {
				padding: 32px 0;
			}
		`,
	)}
`;

export const StyledFooterVersion = styled(FooterTextStyle)`
	bottom: 3px;
	color: var(--color-dark-300);
	font-size: 9px !important;
	line-height: unset;
	position: absolute;
	right: 10px;
	user-select: text;
`;
