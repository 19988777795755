import { TransNamespace } from '../client/modules/translation/TransNamespace';

export default [
	{
		date: '2024-06-07 00:00:00.000',
		list: [
			{
				message: 'New sportsbook',
				namespace: TransNamespace.FEATURES,
			},
		],
	},
	{
		date: '2024-05-13 00:00:00.000',
		list: [
			{
				message:
					'New coins arrived, enjoy playing with {{currencyA}} and {{currencyB}}!',
				values: {
					currencyA: 'Optimistic',
					currencyB: 'Polygon',
				},
			},
		],
	},
	{
		date: '2024-03-04 00:00:00.000',
		list: [
			// {
			// 	message: 'NEW GAME - PLINKO 🤹🔼',
			// 	namespace: TransNamespace.FEATURES,
			// },
			{
				message: 'Updated games layout',
				namespace: TransNamespace.FEATURES,
			},
		],
	},
	{
		date: '2023-10-04 00:00:00.000',
		list: [
			{
				message: 'Redesigned Bonus Codes',
				namespace: TransNamespace.FEATURES,
			},
		],
	},
	{
		date: '2023-08-22 00:00:00.000',
		list: [
			{
				message: 'Upgraded VIP Club',
				namespace: TransNamespace.FEATURES,
			},
		],
	},
	{
		date: '2023-01-22 00:00:00.000',
		list: [
			{
				message: 'Upgraded Dice Game - new range modes',
				namespace: TransNamespace.FEATURES,
			},
		],
	},
	{
		date: '2022-11-16 00:00:00.000',
		list: [
			{
				message: 'New sportsbook',
				namespace: TransNamespace.FEATURES,
			},
		],
	},
	{
		date: '2022-06-23 00:00:00.000',
		list: [
			{
				message:
					'New coins arrived, enjoy playing with {{currencyA}} and {{currencyB}}!',
				values: {
					currencyA: 'USDC',
					currencyB: 'BUSD',
				},
			},
			{
				message: 'All dollar stablecoins are merged into one USD balance',
				namespace: TransNamespace.FEATURES,
			},
		],
	},
	{
		date: '2022-05-13 00:00:00.000',
		list: [
			{
				message: 'Added cashouts on sports bet slips',
				namespace: TransNamespace.FEATURES,
			},
			{
				message: 'Added New Games section in Slots',
				namespace: TransNamespace.FEATURES,
			},
			{
				message: 'Improved VIP Wheel notification during autobet',
				namespace: TransNamespace.FEATURES,
			},
		],
	},
	{
		date: '2022-04-11 00:00:00.000',
		list: [
			{
				message: 'Receive a bonus code every time you reach a new VIP level!',
				namespace: TransNamespace.FEATURES,
			},
			{
				message: 'Spin the VIP Wheel with new exciting reward multipliers!',
				namespace: TransNamespace.FEATURES,
			},
		],
	},
	{
		date: '2021-11-17 00:00:00.000',
		list: [
			{
				message:
					'{{currencyA}}, {{currencyB}}, {{currencyC}} and {{currencyD}} are now {{protocol}} tokens!',
				values: {
					currencyA: 'BTC',
					currencyB: 'ETH',
					currencyC: 'ADA',
					currencyD: 'SHIB',
					protocol: 'BEP20',
				},
			},
		],
	},
	{
		date: '2021-10-20 00:00:00.000',
		list: [
			{
				message: 'The new coin arrived, enjoy playing with {{currency}}!',
				values: {
					currency: 'SHIB',
				},
			},
		],
	},
	{
		date: '2021-09-28 00:00:00.000',
		list: ['Slots!🎰'],
	},
	{
		date: '2021-08-16 00:00:00.000',
		list: [
			{
				message:
					'New coins arrived, enjoy playing with {{currencyA}} and {{currencyB}}!',
				values: {
					currencyA: 'ADA',
					currencyB: 'DOT',
				},
			},
		],
	},
	{
		date: '2021-07-12 00:00:00.000',
		list: [
			{
				message: 'The new coin arrived, enjoy playing with {{currency}}!',
				values: {
					currency: 'BNB',
				},
			},
		],
	},
	{
		date: '2021-07-06 00:00:00.000',
		list: ['Turkish 🇹🇷 Language Version of WOLFBET'],
	},
	{
		date: '2021-06-10 00:00:00.000',
		list: ['Sportsbook has entered the site!⚽🎾🏓🏈🏉🏀🏒🏐'],
	},
	{
		date: '2021-02-22 00:00:00.000',
		list: ['Added turkish 🇹🇷 chat room️️'],
	},
	{
		date: '2021-02-10 00:00:00.000',
		list: [
			{
				message: 'The new coin arrived, enjoy playing with {{currency}}!',
				values: {
					currency: 'SUSHI',
				},
			},
		],
	},
	{
		date: '2021-02-05 00:00:00.000',
		list: [
			{
				message:
					'New coins arrived, enjoy playing with {{currencyA}} and {{currencyB}}!',
				values: {
					currencyA: 'ETC',
					currencyB: 'XLM',
				},
			},
		],
	},
	{
		date: '2021-01-28 00:00:00.000',
		list: [
			{
				message: 'The new coin arrived, enjoy playing with {{currency}}!',
				values: {
					currency: 'UNI',
				},
			},
		],
	},
	{
		date: '2021-01-13 00:00:00.000',
		list: ['Upgraded VIP Wheel - Lossback based, bigger prizes!'],
	},
	{
		date: '2020-12-16 00:00:00.000',
		list: [
			{
				message: 'The new coin arrived, enjoy playing with {{currency}}!',
				values: {
					currency: 'USDT',
				},
			},
		],
	},
	{
		date: '2020-12-10 00:00:00.000',
		list: ['VIP Wheel - new feature for High Rollers!'],
	},
	{
		date: '2020-11-16 00:00:00.000',
		list: ['Upgraded DICE animation and sounds ✨'],
	},
	{
		date: '2020-10-21 00:00:00.000',
		list: ['Install WOLFBET as an app to your homescreen'],
	},
	{
		date: '2020-10-09 00:00:00.000',
		list: ['Wolf Race prize pool increased to $1,000 daily!'],
	},
	{
		date: '2020-09-29 00:00:00.000',
		list: ['NEW GAME - LIMBO 🎯️🌡️', 'New website layout'],
	},
	{
		date: '2020-08-24 00:00:00.000',
		list: [
			'New autobet mode - ⚡ FLASHBET',
			'Added Auto sessions history in Transactions Page',
			'Added sharing Auto sessions to the public',
		],
	},
	{
		date: '2020-08-18 00:00:00.000',
		list: ['Added spanish 🇪🇸 chat room️️'],
	},
	{
		date: '2020-08-06 00:00:00.000',
		list: ['Fixed "out of memory" error during playing autobet mode'],
	},
	{
		date: '2020-07-17 00:00:00.000',
		list: [
			'Possibility to move up and down each condition in Autobet Expert mode',
		],
	},
	{
		date: '2020-07-16 00:00:00.000',
		list: [
			'Manual and autobet form settings will be remembered after page refresh',
			'Improved experience in applying strategy from chat',
		],
	},
	{
		date: '2020-06-30 00:00:00.000',
		list: [
			'Autobet 2.0 - Super upgrade (30+ possibilities)',
			'Crypto/USD converter in the game form',
			'Refreshed autobetting view',
			'Default strategies for autobet mode',
		],
	},
	{
		date: '2020-06-17 00:00:00.000',
		list: ['Added portuguese 🇵🇹 chat room️️'],
	},
	{
		date: '2020-05-31 00:00:00.000',
		list: ['Added friends and private messages'],
	},
	{
		date: '2020-05-07 00:00:00.000',
		list: ['Currency converter available on desktops in the footer'],
	},
	{
		date: '2020-04-22 00:00:00.000',
		list: ['Ranking 🏆'],
	},
	{
		date: '2020-04-21 00:00:00.000',
		list: ['Optimized HILO is available again'],
	},
	{
		date: '2020-04-17 00:00:00.000',
		list: ['NEW GAME - HILO ♦️♣️♥️♠️'],
	},
	{
		date: '2020-04-03 00:00:00.000',
		list: [
			'Filipino 🇵🇭 and Indonesian 🇮🇩 Language Version of WOLFBET',
			'Vault 🏦',
		],
	},
	{
		date: '2020-03-06 00:00:00.000',
		list: [
			'API with official documentation 🤖',
			'New affiliate features - campaign details with more stats 📊 and cashout history 📜',
		],
	},
	{
		date: '2020-03-02 00:00:00.000',
		list: ['Added Japanese 🇯🇵 and German 🇩🇪 Language Version of WOLFBET'],
	},
	{
		date: '2020-02-25 00:00:00.000',
		list: ['Increased limits for the max bet and max profit for all coins'],
	},
	{
		date: '2020-02-21 00:00:00.000',
		list: [
			'Bets Archive - check your full bets history and statistics per day',
			'Added sound notification when autobet stopped in the background',
			'Enabled change seed during autobet session',
			'Enabled login action on two devices at the same time',
		],
	},
	{
		date: '2020-02-17 00:00:00.000',
		list: [
			'Improved browsing My bets history on Transactions page during autobet',
			'Added instruction video to Authy - two factor authenticator supporting multiple platforms',
		],
	},
	{
		date: '2020-02-04 00:00:00.000',
		list: ['Improved bets speed ⚡️️'],
	},
	{
		date: '2020-01-20 00:00:00.000',
		list: ['Added filipino 🇵🇭 and indonesian 🇮🇩 chat rooms️️'],
	},
	{
		date: '2020-01-13 00:00:00.000',
		list: [
			'New advanced autobet settings added ⚙️️',
			'Increased 2x rainbot amount and up to 20 users',
			'Disabled faucet feature due to abuse',
		],
	},
	{
		date: '2019-12-23 00:00:00.000',
		list: [
			'The new coin arrived! Enjoy playing with XRP!',
			'Newly generated deposit addresses are static!',
			'Enabled manual input of the client seed',
		],
	},
	{
		date: '2019-12-20 00:00:00.000',
		list: ['Christmas Theme! 🎅', 'Others tab in Transactions page is back'],
	},
	{
		date: '2019-12-17 00:00:00.000',
		list: [
			'All users can make RAIN on chat',
			'Tipping is enabled for every user!',
			'You can check the race history of other players',
			'Race winnings are included in profit on User Details',
			'Badges are enabled in race leaderboard!',
		],
	},
	{
		date: '2019-12-10 00:00:00.000',
		list: [
			'Now you can check your race winnings history in Statistics',
			'Fixed edge cases with showing user badges',
		],
	},
	{
		date: '2019-12-04 00:00:00.000',
		list: [
			'Fixed saving betting strategies',
			'Fixed rare cases with hidden settings',
		],
	},
	{
		date: '2019-11-28 00:00:00.000',
		list: [
			'Score TOP 3 in the WOLF RACE and get a Race Badge',
			'Now you can share on chat and save your autobet strategies!',
			'More compact design of bet details in chat messages',
		],
	},
	{
		date: '2019-11-27 00:00:00.000',
		list: [
			'Bet details preview in chat messages',
			// 'Review your race winnings history in Statistics',
			'Are you a roll hunter? Now you can disable autobet summary in a game settings',
		],
	},
	{
		date: '2019-11-21 00:00:00.000',
		list: [
			'Autobet Summary modal will appear on stop or error of autobet session',
			'Improved notification about gaining Wolf Pack ranks',
		],
	},
	{
		date: '2019-11-14 00:00:00.000',
		list: [
			'Chat message length extended to 320 characters',
			// 'Fixed login via Metamask',
			'Minor style fixes',
		],
	},
	{
		date: '2019-11-06 00:00:00.000',
		list: [
			'New transactions tab where you can check your giveaway, bonus, race winnings and tips history',
			'Now you can switch Wolf Race leaderboard to full TOP 30 view',
		],
	},
	{
		date: '2019-11-05 00:00:00.000',
		list: [
			'WOLF PACK - VIP program with rakeback',
			'Improved notification system',
		],
	},
	{
		date: '2019-10-28 00:00:00.000',
		list: [
			'Chat Rules modal',
			'Advanced autobet options',
			'Extended betting session stats',
		],
	},
	{
		date: '2019-10-20 00:00:00.000',
		list: [
			'Improved chat experience, unread messages, mention notification',
			'Increased win chance decimal point',
		],
	},
	{
		date: '2019-10-16 00:00:00.000',
		list: [
			'Added Hotkeys feature in dropdown settings',
			'Ability to set up auto bet speed',
			'Instructions on how to deposit CSGO skins',
		],
	},
	{
		date: '2019-10-10 00:00:00.000',
		list: [
			'Added stats on mobile',
			'Fixed balance update after rain',
			'Added Transaction ID in deposit history with blockchain explorer linked',
		],
	},
	{
		date: '2019-10-08 00:00:00.000',
		list: ['Portuguese Language Version of WOLFBET', 'Live stats chart v2.0'],
	},
	{
		date: '2019-10-03 00:00:00.000',
		list: [
			'Added keyboard controlled user assign list for chat',
			'Fixed wallet logo replacement in chat message',
			'Improved memory and CPU usage',
		],
	},
	{
		date: '2019-09-26 00:00:00.000',
		list: ['More frequent rain of BTC, LTC, ETH, BCH', 'Live stats chart'],
	},
	{
		date: '2019-09-25 00:00:00.000',
		list: [
			'Full HD experience for low resolution screens (width 820px-1450px)',
		],
	},
	{
		date: '2019-09-23 00:00:00.000',
		list: ['Russian Chat', 'Russian Language Version of WOLFBET'],
	},
	{
		date: '2019-09-19 00:00:00.000',
		list: [
			'Faster bets and other server calculations',
			'Fixed WOLF RACE timer',
			'Added players before and after in the WOLF RACE',
			'Added your position in last race if not in TOP 10',
			'Updated form styling',
			'Frontend optimizations',
		],
	},
	{
		date: '2019-09-13 00:00:00.000',
		list: ['Fixed roll over/under', 'Some stable fixes'],
	},
];
