import styled, { css } from 'styled-components';
import { ButtonBorderRadius } from '@ui/button/mixins/button.border-radius';
import { Size } from '@uiTypes/Size';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { NOTIFICATION_ICON_CLASS_NAME } from '@legacyApp/client/components/notificationIcon/NotificationIcon';
import { ButtonPadding } from './mixins/button.padding';
import { ButtonBackground } from './mixins/button.background';
import { ButtonColor } from './mixins/button.color';
import { ButtonProps } from './index';

export interface ButtonStyledProps
	extends Omit<ButtonProps, 'styleType' | 'size'> {
	$iconAfter: boolean;
	$isLabel: boolean;
	disabled?: ButtonProps['disabled'];
	$type: ButtonProps['styleType'];
	$size: ButtonProps['size'];
	$border: ButtonProps['borderType'];
}

export const ButtonIconStyled = styled.div.attrs({
	className: 'text-style-sm-medium',
})``;

export const ButtonStyled = styled.button<ButtonStyledProps>`
	align-items: center;
	background-color: transparent;
	border: 0 none;
	color: inherit;
	cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
	display: flex;
	flex-flow: row;
	margin: 0;
	max-width: 100%;
	outline: none;
	position: relative;
	transition: 300ms;
	vertical-align: top;

	${ButtonBorderRadius};
	${ButtonColor};
	${ButtonBackground};
	${ButtonPadding};

	span {
		float: left;
		max-width: 100%;
		white-space: nowrap;

		> span {
			float: none;
		}
	}

	.${NOTIFICATION_ICON_CLASS_NAME} {
		left: -5px;
		right: unset;
		top: -5px !important;
	}

	${ButtonIconStyled} {
		align-items: center;
		display: flex;
		float: left;
		justify-content: flex-start;
		padding: 0;
		position: unset;
		width: unset;
		${(props) => {
			if (!props.$isLabel) {
				return null;
			}
			const marginValue = props.$size === Size.SMALL ? 4 : 6;
			return css`
			margin-${props.$iconAfter ? 'left' : 'right'}: ${marginValue}px!important;
		`;
		}}

		i:not(:has(svg)),
		> svg,
		> div > svg,
		img, span {
			${(props) => {
				if (props.$size === Size.SMALL) {
					if (props.$isLabel) {
						return css`
							font-size: 12px;
							height: 12px;
							line-height: 12px;
							min-height: 12px;
							min-width: 12px;
							width: 12px;
						`;
					}
					return css`
						font-size: 10px;
						height: 10px;
						line-height: 10px;
						min-height: 10px;
						min-width: 10px;
						width: 10px;
					`;
				}
				return css`
					font-size: 16px;
					height: 16px;
					line-height: 16px;
					min-height: 16px;
					min-width: 16px;
					width: 16px;
				`;
			}}
		}
	}
`;

ButtonStyled.defaultProps = {
	$size: Size.MEDIUM,
	$type: ButtonStyleType.PRIMARY,
};
