import styled from 'styled-components';
import { BalanceCurrencyWrapperStyle } from '@common/components/number/balanceAmount/balanceCurrencyWrapper.style';
import { BalanceCurrencyValueStyle } from '@common/components/number/balanceAmount/balanceCurrencyValue.style';
import { CurrencyLogoStyle } from '../../currencySymbol/currencySymbol.style';
import { boxNoHeight } from '../../../modules/style/defaults';
import { StyledBorderBox } from '../conditionBlockEdit/ConditionBlockEdit.styled';

export const StyledExpertConfigTemplate = styled.span.attrs({
	className: 'text-style-caption-medium',
})`
	color: ${(props) => props.theme.colors.textDisabled};
	text-transform: uppercase;
`;

export const StyledExpertConfig = styled.div.attrs({
	className: 'expert-config',
})`
	${boxNoHeight};
	position: relative;
	padding: 8px;

	p {
		margin-top: 4px !important;
		margin-bottom: 4px;
	}

	span {
		display: inline-block;
		margin-right: 4px;

		${CurrencyLogoStyle} {
			display: inline-flex !important;
			margin-right: 4px;
		}

		&:last-of-type {
			margin-right: 0;
		}
	}

	${BalanceCurrencyWrapperStyle} {
		display: inline-flex !important;
	}

	${BalanceCurrencyValueStyle} {
		margin-right: 0;

		span {
			margin-right: 0;
		}
	}
`;

export const StyledExpertConfigDescription = styled.p.attrs({})`
	margin: 8px 0 0;
	line-height: 1.5;

	* {
		text-transform: lowercase;
	}

	&:first-of-type {
		margin: 0 !important;
	}
`;

export const StyledExpertBorderBox = styled(StyledBorderBox)`
	display: grid;
	grid-template-columns: 30px calc(100% - 60px) 30px;
`;
