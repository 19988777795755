import { css } from 'styled-components';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { handleHover } from '@legacyApp/client/modules/style/mixins';
import { ButtonStyledProps } from '../Button.styled';

export const ButtonBackground = (props: ButtonStyledProps) => {
	if (
		props.$type === ButtonStyleType.CLEAR ||
		props.$type === ButtonStyleType.LABEL
	) {
		return css`
			background: transparent;
		`;
	}

	if (props.$type === ButtonStyleType.OUTLINE) {
		return css`
			background: transparent;
			box-shadow: inset 0 0 0 1px var(--color-dark-500);
			${handleHover(css`
				background: var(--color-white-white-5);
			`)}
		`;
	}

	if (props.disabled) {
		return css`
			background: var(--color-dark-600);
		`;
	}

	if (props.$type === ButtonStyleType.PRIMARY) {
		return css`
			background: var(--color-misc-primary-button);

			${handleHover(css`
				background: var(--color-misc-primary-button-hover);
			`)}

			:focus {
				box-shadow: inset 0 0 0 1px var(--color-white-white-50);
			}
		`;
	}

	if (props.$type === ButtonStyleType.SECONDARY) {
		return css`
			background: var(--color-dark-500);

			${handleHover(css`
				background: var(--color-misc-secondary-button-hover);
			`)}

			:focus {
				box-shadow: inset 0 0 0 1px var(--color-white-white-50);
			}
		`;
	}

	return null;
};
