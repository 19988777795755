import {
	ButtonHTMLAttributes,
	FC,
	MutableRefObject,
	PropsWithChildren,
	ReactNode,
} from 'react';
import classnames from 'classnames';
import { Size } from '@uiTypes/Size';
import { ButtonStyleType } from '@uiTypes/ButtonStyleType';
import { InputStyleType } from '@uiTypes/InputStyleType';
import { ButtonIconStyled, ButtonStyled } from './Button.styled';

export interface ButtonProps
	extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
	label?: string | ReactNode;
	size?: Size;
	borderType?: InputStyleType;
	styleType?: ButtonStyleType;
	disabled?: ButtonHTMLAttributes<HTMLButtonElement>['disabled'];
	iconBefore?: ReactNode;
	iconAfter?: ReactNode;
	refElement?: MutableRefObject<any>;
	onlyIcon?: boolean;
}

export const Button: FC<ButtonProps> = (props) => {
	const {
		children,
		label,
		iconBefore,
		iconAfter,
		styleType,
		size,
		className,
		borderType,
		refElement,
		onlyIcon,
		...restProps
	} = props;

	return (
		<>
			<ButtonStyled
				ref={refElement}
				className={classnames(
					{
						'text-style-sm-medium': !!label,
					},
					styleType,
					borderType,
					size,
					className,
				)}
				$type={styleType}
				$size={size}
				$border={borderType}
				$iconAfter={!!iconAfter}
				$isLabel={onlyIcon ? false : !!label || !!children}
				{...restProps}
			>
				{iconBefore && <ButtonIconStyled>{iconBefore}</ButtonIconStyled>}
				{label && <span>{label}</span>}
				{children}
				{iconAfter && <ButtonIconStyled>{iconAfter}</ButtonIconStyled>}
			</ButtonStyled>
		</>
	);
};
