import { FunctionComponent, MouseEventHandler, PropsWithChildren } from 'react';
import { useAppSelector } from '@legacyApp/hooks/store/useAppSelector';

const languages = ['en', 'ru', 'ph', 'id', 'jp', 'pt', 'de'];

const useHref = (href: string): string => {
	const language = useAppSelector((state) => state.app.language);
	if (language === 'en') {
		return href;
	}
	if (!href) {
		return href;
	}
	if (href.indexOf('http') > -1 || href.indexOf('mailto:') > -1) {
		return href;
	}
	if (languages.some((lang) => href.indexOf(`/${lang}`) === 0)) {
		return href;
	}
	return `${language ? `/${language}` : ''}${href}`;
};

export const Redirect: FunctionComponent<
	PropsWithChildren<{
		href: string;
		target?: string;
		rel?: string;
		className?: string;
		onClick?: MouseEventHandler<HTMLElement>;
		style?: any;
	}>
> = ({ href, children, target, rel, className, onClick, style }) => {
	const link = useHref(href);

	return (
		<a
			onClick={onClick}
			className={className}
			target={target}
			rel={rel}
			href={link}
			style={style}
		>
			{children}
		</a>
	);
};
