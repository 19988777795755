import * as queryString from 'querystring';
import {
	openModal,
	openSecurityWarningModal,
} from '@modules/modals/store/modal.actions';
import { MODAL_ID } from '@common/constants/modal/ids.modal.constants';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { generateUserVerificationStatusUrl } from '@modules/user-verifiaction/methods/generateUserVerificationStatusUrl';
import { UserVerificationLevel } from '@modules/user-verifiaction/types/UserVerificationLevel';
import { UserVerificationLevelStatus } from '@modules/user-verifiaction/types/UserVerificationLevelStatus';
import { vipWheelGetFundsThunk } from '@modules/vip/store/thunks/vipWheelGetFunds.thunk';
import { getVipBenefitValue } from '@modules/vip/hooks/useVipBenefitState';
import { VipBenefits } from '@modules/vip/constants/vip.config';
import { vipGetLevelsThunk } from '@modules/vip/store/thunks/vipGetLevels.thunk';
import { VIP_BENEFITS } from '@modules/vip/constants/VIP_BENEFITS';
import { isRemind2fa } from '../../../modules/app/userService';
import { showAlert } from '../../alerts/alerts.actions';
import { trans } from '../../../modules/translation/translate';
import routingService from '../../../modules/app/routingService';
import { fetchApi } from '../../fetch/fetch.thunk';
import { appGoTo } from '../../app/app.actions';
import { notificationEnable } from '../../notification/notification.actions';
import { NOTIFICATION_ID } from '../../notification/notification.constants';
import { clearFirstLogin } from '../user.actions';
import { second } from '../../../modules/app/time';
import { UserProfileResponse } from './getUserData.thunk';

const isAnyModalOpen = (state) => {
	const query = queryString.parse(
		state.router.location.search.replace('?', ''),
	);
	return !!(state.modal?.id || query?.modal);
};

const initModalCallback = (dispatch, state, data) => {
	// return dispatch(dailyStreakFetch()).then(dailyStreak => {
	// if (dailyStreak && canOpenModal(dailyStreak.history)) return dispatch(openModal(DAILY_STREAK_MODAL));
	if (isAnyModalOpen(state)) {
		return;
	}
	if (data?.user && state.user.isFirstLogin) {
		return dispatch([
			// openModal(DAILY_STREAK_MODAL'),
			clearFirstLogin(),
		]);
	}
	if (!state.user.isLogged && data?.user) {
		if (
			isRemind2fa() &&
			(!data.user.email || !data.user.two_factor_authentication)
		) {
			if (
				(!data.user.email && !data.user.two_factor_authentication) ||
				(!data.user.email && data.user.two_factor_authentication)
			) {
				return dispatch(openSecurityWarningModal());
			}
			if (data.user.email && !data.user.two_factor_authentication) {
				return dispatch(openModal(MODAL_ID.REMIND_2FA, 'initModalCallback'));
			}
		}
	}
	// });
};

export const userHandleInitModal = (
	data: UserProfileResponse,
	isInitialized: boolean,
) => {
	return async (dispatch, getState) => {
		// timeout due to closing modals on redirects,
		// after login we are redirected to /auth page
		// on which is triggered getUserData action and
		// initial modals and then this page redirects
		// to main page which causes closing of initial modals
		const timeout =
			getState().router.location.href.indexOf('/auth') > -1 ? 5 * second : 0;
		setTimeout(async () => {
			const state = getState();
			if (state.modal?.id === MODAL_ID.INSANE_BONUS_CODE_INFO) {
				return null;
			}
			if (
				data?.user?.has_kyc_to_verify &&
				state.router.location.pathname !==
					routingService.to(ROUTING_ID.USER_VERIFICATION)
			) {
				return dispatch(handleKycToVerify());
			}
			const levels = await dispatch(await vipGetLevelsThunk());
			if (
				getVipBenefitValue(
					levels[data?.user?.vip_level - 1],
					VipBenefits.DAILY_CHARGE,
				)
			) {
				return dispatch(vipWheelGetFundsThunk()).then((funds) => {
					if (!funds.availableAt && funds.availableFunds?.length) {
						dispatch(
							notificationEnable(
								NOTIFICATION_ID.HAS_VIP_WHEEL_TO_SPIN,
								isInitialized
									? () => {
											dispatch(
												showAlert(
													'info',
													`${
														VIP_BENEFITS[VipBenefits.DAILY_CHARGE].name
													} is unlocked! Claim it now!`,
													true,
												),
											);
									  }
									: null,
							),
						);
						if (!isInitialized && !isAnyModalOpen(state)) {
							dispatch(openModal(MODAL_ID.VIP_WHEEL, 'userHandleInitModal'));
						}
						return;
					}
					return initModalCallback(dispatch, state, data);
				});
			}
			return initModalCallback(dispatch, state, data);
		}, timeout);
	};
};

export const handleKycToVerify = () => {
	return (dispatch) => {
		return dispatch(
			fetchApi(generateUserVerificationStatusUrl(), {
				Authorization: true,
			}),
		)
			.then((response: UserVerificationLevel) => {
				const { status } = response || {};
				if (UserVerificationLevelStatus.ACTION_REQUIRED === status) {
					return dispatch(
						openModal(MODAL_ID.REQUIRED_ACTION_KYC, 'handleKycToVerify'),
					);
				}
				if (UserVerificationLevelStatus.EMPTY === status) {
					return dispatch(
						openModal(MODAL_ID.REQUIRED_KYC, 'handleKycToVerify'),
					);
				}
				if (status === UserVerificationLevelStatus.DECLINED) {
					return dispatch(appGoTo(ROUTING_ID.USER_VERIFICATION));
				}
			})
			.catch((error) => {
				return console.log(error);
			});
	};
};

export const bonusInfo = () => {
	return (dispatch, getState) => {
		const state = getState();
		if (state.router.location.href.indexOf('bonus-code') > -1) {
			return;
		}
		return dispatch(
			showAlert(
				'info',
				trans({
					label:
						'We have granted you a new bonus code! Go to the VIP Bonus Code tab and claim it!',
				}),
			),
		);
	};
};
