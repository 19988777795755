import classnames from 'classnames';
import styled, { css } from 'styled-components';

import { calcStyle } from '@legacyApp/client/modules/style/methods/calcStyle';
import { chatViewport } from '@legacyApp/client/modules/style/methods/chatViewport';
import { fontSize } from '@legacyApp/client/modules/style/mixins/fontSize';

export interface BalanceCurrencyValueProps {
	className?: string;
	$color?: string;
}

export interface BalanceCurrencyValueBigProps {
	className?: string;
	value?: string | number;
}

export interface BalanceCurrencyValueSmallProps {
	className?: string;
}

export const BalanceCurrencyValueBigStyle = styled.span.attrs<BalanceCurrencyValueBigProps>(
	(props) => ({
		className: classnames('balance__currency--value-big', props.className),
	}),
)<BalanceCurrencyValueBigProps>`
	${(props) =>
		chatViewport(
			(viewportPadding) => css`
				${fontSize({
					size: '11px',
					sizeDesktop: '13px',
					minWidth: calcStyle({
						a: props.theme.media.minWidthSmallTablet,
						b: viewportPadding,
					}),
					sizeBigScreen: '15px',
				})}
			`,
		)}
`;

export const BalanceCurrencyValueSmallStyle = styled.span.attrs<BalanceCurrencyValueSmallProps>(
	(props) => ({
		className: classnames('balance__currency--value-small', props.className),
	}),
)<BalanceCurrencyValueSmallProps>`
	${chatViewport(
		(viewportPadding) => css`
			position: relative;
			${(props) =>
				fontSize({
					size: '9px',
					sizeDesktop: '10px',
					minWidth: calcStyle({
						a: props.theme.media.minWidthDesktop,
						b: viewportPadding,
					}),
					sizeBigScreen: '12px',
				})}
			${(props) =>
				fontSize({
					label: 'line-height',
					size: '12px',
					sizeDesktop: '13px',
					minWidth: calcStyle({
						a: props.theme.media.minWidthDesktop,
						b: viewportPadding,
					}),
					sizeBigScreen: '15px',
				})}
		`,
	)}
`;

export const BalanceCurrencyValueStyle = styled.span.attrs<BalanceCurrencyValueProps>(
	(props) => ({
		className: classnames('balance__currency--value', props.className),
	}),
)<BalanceCurrencyValueProps>`
	overflow: hidden;
	text-align: right;
	white-space: nowrap;

	* {
		white-space: nowrap;
		${(props) =>
			props.$color
				? css`
						color: ${typeof props.$color === 'string'
							? props.$color
							: props.theme.colors.positive};
				  `
				: ''}
	}
`;
