import React, { FC } from 'react';
import { VipIcon } from '@ui/vip-icon';
import { UserNameProps } from '@ui/user-name/index';
import styled from 'styled-components';
import {
	OnlineIcon,
	StyledOnlineIcon,
} from '@common/components/user/UserLogin/OnlineIcon';
import { UserIcon } from '@common/components/user/UserIcon';
import { ModeratorBadge } from '@common/components/user/UserLogin/ModeratorBadge';
import { RaceBadge } from '@common/components/user/UserLogin/RaceBadge';

export const UserNameIcons: FC<UserNameProps> = ({ user, options, isMe }) => {
	if (options?.prefix || options?.noBadges) {
		return null;
	}

	const isDisabledBadges = user.admin && !isMe;
	const noIcons =
		options.noLevelIcon && !user.moderator && !user.raceBadge && !user.vipLevel;

	return (
		<UserNameIconsStyled>
			{options.isOnline && !isMe && <OnlineIcon />}
			{noIcons ? (
				<UserIcon />
			) : (
				<>
					{user.moderator && <ModeratorBadge />}
					{!isDisabledBadges && (
						<>
							<RaceBadge size={18} data={user.raceBadge} />
							{!options.noLevelIcon && (
								<VipIcon size={21} level={user.vipLevel} />
							)}
						</>
					)}
				</>
			)}
		</UserNameIconsStyled>
	);
};

export const UserNameIconsStyled = styled.span`
	align-items: center;
	display: inline-flex;
	gap: 4px;

	> *:last-child {
		margin-right: 4px;
	}

	${StyledOnlineIcon} {
		margin: 0 !important;
	}
`;
