import dynamic from 'next/dynamic';
import React, { FC } from 'react';

import { EnhancedNextComponentPageProps } from '@common/hoc/pageEnhancer';

import { BottomMenu } from '@common/components/layout/BottomMenu';
import { ConfirmContainer } from '@legacyApp/client/components/confirm';
import { Cover } from '@legacyApp/client/components/cover';
import { DropdownMenu } from '@legacyApp/client/components/dropdown/dropdownMenu';
import { InfoModalContainer } from '@legacyApp/client/components/infoModal';
import { Loadable } from '@legacyApp/client/components/loader/loadable/loadable';
import { TestMenu } from '@legacyApp/client/components/TestGTM';
import { Modal } from '@modules/modals/modal';
import { Performance } from '@modules/performance/components';

const SupportToggle = Loadable(
	{
		loader: () =>
			import(
				/* webpackChunkName: "supportToggle" */ '@modules/support/components/FloatingSupportToggle/floatingSupportToggle'
			),
		ssr: false,
	},
	'src/LegacyApp/client/components/supportToggle/supportToggle',
);

const AlertsWrapperContainer = Loadable(
	{
		loader: () =>
			import(
				/* webpackChunkName: "AlertsWrapperContainer" */ 'src/common/components/alertsWrapper/AlertsWrapper.container'
			),
		ssr: false,
	},
	'src/common/components/alertsWrapper/AlertsWrapper.container',
);

const CaptchaStoreController = Loadable(
	{
		loader: () =>
			import(
				/* webpackChunkName: "CaptchaStoreController" */ 'src/modules/captcha/components/CaptchaStoreController'
			),
		ssr: false,
	},
	'src/modules/captcha/components/CaptchaStoreController',
);

const ChatContainer = Loadable(
	{
		loader: () =>
			import(
				/* webpackChunkName: "Chat.wrapper" */ 'src/modules/chat/components/wrapper/Chat.wrapper'
			),
		ssr: false,
	},
	'src/modules/chat/components/wrapper/Chat.wrapper',
);

const SideMenu = dynamic(() => import('src/modules/SideMenu'), {
	ssr: false,
});

export const AsyncModules: FC<EnhancedNextComponentPageProps> = ({
	props,
	id,
}) => {
	return (
		<>
			<CaptchaStoreController />

			<Cover />

			{props?.noHeader ? null : <SideMenu />}

			{props?.noChat ? null : <ChatContainer />}

			{props?.noBottomMenu ? null : <BottomMenu pageId={id} />}

			<AlertsWrapperContainer />

			<Modal />

			<InfoModalContainer />

			<ConfirmContainer />

			<SupportToggle />

			<DropdownMenu />

			<TestMenu />

			<Performance />
		</>
	);
};
