import { css } from 'styled-components';
import { Size } from '@uiTypes/Size';
import { ButtonStyledProps } from '../Button.styled';

export const ButtonPadding = (props: ButtonStyledProps) => {
	if (props.$size === Size.SMALL) {
		return css`
			padding: ${props.$isLabel ? '4px 16px' : '7px'};
		`;
	}

	if (props.$size === Size.MEDIUM) {
		return css`
			padding: ${props.$isLabel ? '8px 24px' : '8px'};
		`;
	}

	if (props.$size === Size.LARGE) {
		return css`
			padding: ${props.$isLabel ? '12px 24px' : '12px'};
		`;
	}

	return null;
};
