import { ConnectedRouter } from 'connected-next-router';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { appWithTranslation } from 'next-i18next';
import Router from 'next/router';
import { ThemeProvider } from 'styled-components';

import { Localization } from '@common/components/default/Localization/Localization';
import { PageLoadingProgressBar } from '@common/components/default/PageLoadingProgressBar/PageLoadingProgressBar';
import { StaticPropsContext } from '@common/context/app/StaticPropsContext';
import { currenciesSettings } from '@common/controllers/currency/currenciesSettings';
import { appWithPrevalProps } from '@common/hoc/appWithPrevalProps';
import { EnhancedNextComponent } from '@common/hoc/pageEnhancer';
import { useCheckAccessDenied } from '@common/hooks/useCheckAccessDenied';
import { theme } from '@legacyApp/client/modules/style/theme';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { wrapper } from '@legacyApp/client/store/configure.store';
import { useCheckNamespaces } from '@legacyApp/hooks/translation/useCheckNamespaces';
import { PageConfigProvider } from '@modules/page/PageConfig/PageConfig.provider';
import { CurrenciesSettings } from '@legacyApp/types/wallet/CurrenciesSettings';
import { SideMenuProvider } from '@modules/SideMenu/SideMenu.provider';

import { App } from '@common/components/app/App';
import { FullscreenLoading } from '@legacyApp/client/components/loading/FullScreenLoading';
import { InsaneBonusCodeWrapper } from '@modules/vip/components/BonusCode/InsaneBonusCodeWrapper';

import '@common/vendor/requestAnimationFrame.polyfill';
import '@legacyApp/client/modules/app/sentryService';
import '@legacyApp/client/styles/styles.scss';

export function reportWebVitals(metric) {
	metric.label === 'web-vital' &&
		console.log(metric.label, metric.name, metric.value, metric);
}

Router.events.on('routeChangeComplete', (url, options) => {
	// console.log('routeChangeComplete', {url, options});
	if (!options.shallow && process.browser) {
		// pageScrollTo({ top: 0 });
	}
});

type MyAppProps = AppProps & {
	Component: EnhancedNextComponent;
	pageProps: AppProps['pageProps'] & {
		namespaces: TransNamespace[];
		currenciesSettingsData: CurrenciesSettings;
	};
};

function MyApp(props: MyAppProps) {
	const { Component, pageProps } = props;

	useCheckNamespaces({ namespaces: pageProps?.namespaces });
	useCheckAccessDenied();

	if (
		pageProps?.currenciesSettingsData &&
		!currenciesSettings.checkSettings(currenciesSettings.settings)
	) {
		currenciesSettings.setSettings(pageProps.currenciesSettingsData);
	}

	return (
		<ConnectedRouter>
			<ThemeProvider theme={theme}>
				<PageConfigProvider id={Component?.pageConfig?.id}>
					<SideMenuProvider disabled={false}>
						<PageLoadingProgressBar />
						<Head>
							<meta charSet="utf-8" />
							<meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
							<meta
								name="viewport"
								content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1, viewport-fit=cover, user-scalable=yes"
							/>
							<meta name="google" content="notranslate" />
							<meta name="mobile-web-app-capable" content="yes" />
							<meta name="apple-mobile-web-app-capable" content="yes" />
							<meta name="theme-color" content="#ffffff" />
							<link rel="preconnect" href="https://fonts.googleapis.com" />
							<link
								rel="preconnect"
								href="https://fonts.gstatic.com"
								crossOrigin="true"
							/>
							<link rel="dns-prefetch" href="https://fonts.googleapis.com" />
							<link rel="dns-prefetch" href="https://fonts.gstatic.com" />
							<link
								href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,700;1,700&family=Maven+Pro:wght@400;500;600&family=Inter:wght@400;500;600&display=swap"
								rel="stylesheet"
							/>
						</Head>
						<StaticPropsContext.Provider
							// @ts-expect-error invalid type
							value={pageProps}
						>
							<InsaneBonusCodeWrapper>
								<FullscreenLoading>
									<App {...Component.pageConfig}>
										<Component {...Component.pageConfig} {...pageProps} />
									</App>
								</FullscreenLoading>
							</InsaneBonusCodeWrapper>
						</StaticPropsContext.Provider>
						<Localization />
					</SideMenuProvider>
				</PageConfigProvider>
			</ThemeProvider>
		</ConnectedRouter>
	);
}

// @ts-expect-error invalid type
export default wrapper.withRedux(appWithPrevalProps(appWithTranslation(MyApp)));
