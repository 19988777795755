import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useOnMount } from '@common/hooks/useOnMount';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { useAppMobile } from '@common/selectors/app.selectors';
import { useLocationChange } from '@common/hooks/useLocationChange';
import { useAuthInitialized } from '@common/hooks/useAuthInitialized';
import { useUserIsLogged } from '@common/selectors/user.selectors';
import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { usePrevious } from '@legacyApp/hooks/render/usePrevious';
import { setLockedView } from '@legacyApp/client/store/app/app.actions';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { chatActions } from '../chat/store/chat.actions';
import { useChatVisible } from '../chat/store/selectors/useChatVisible';
import { SideMenuContext } from './context/SideMenu.context';

const INIT_LS_VALUE = lsGet(LocalStorageKeys.SIDE_MENU_IS_OPEN, null);

export const SideMenuProvider: FC<PropsWithChildren<{ disabled: boolean }>> = ({
	children,
	disabled,
}) => {
	const isMobile = useAppMobile();
	const [isOpen, setIsOpen] = useState(null);
	const isChatVisible = useChatVisible();
	const dispatch = useDispatch();
	const isLogged = useUserIsLogged();
	const { isInitialized } = useAuthInitialized({ id: ROUTING_ID.HOME });

	const prevIsInitialized = usePrevious(isInitialized);

	const setIsOpen_ = useCallback(
		(_isOpen) => {
			if (_isOpen === isOpen) {
				return;
			}
			if (disabled) {
				_isOpen = false;
			}
			if (_isOpen) {
				document.body.classList.add('left-menu-visible');
				document.body.classList.remove('left-menu-hidden');
			} else {
				document.body.classList.remove('left-menu-visible');
				document.body.classList.add('left-menu-hidden');
			}
			if (isMobile) {
				dispatch(setLockedView('sideMenuProvider', _isOpen));
			}
			setIsOpen(_isOpen);
			lsSet(LocalStorageKeys.SIDE_MENU_IS_OPEN, _isOpen);
			if (isChatVisible && _isOpen) {
				dispatch(chatActions.setVisible({ isVisible: false }));
			}
		},
		[disabled, dispatch, isChatVisible, isMobile, isOpen],
	);

	useLocationChange(() => {
		if (isMobile && isOpen) {
			console.log('useLocationChange');
			setIsOpen_(false);
		}
	});

	useOnMount(() => {
		const isOpen_ =
			disabled || isMobile
				? false
				: !!lsGet(LocalStorageKeys.SIDE_MENU_IS_OPEN);
		setIsOpen_(isOpen_);
	});

	useEffect(() => {
		if (
			!prevIsInitialized &&
			isInitialized &&
			isLogged &&
			!isOpen &&
			INIT_LS_VALUE === null &&
			!disabled &&
			!isMobile
		) {
			setIsOpen_(true);
		}
	}, [
		disabled,
		isInitialized,
		isLogged,
		isMobile,
		isOpen,
		prevIsInitialized,
		setIsOpen_,
	]);

	return (
		<SideMenuContext.Provider value={{ isOpen, setIsOpen: setIsOpen_ }}>
			{children}
		</SideMenuContext.Provider>
	);
};
