import { ROUTING_ID } from '@common/constants/routing/ids.routing.constants';
import { TokenNames } from '@common/constants/currency/settings/tokenNames';
import { SPORTS_META } from '@modules/sports/constants/sports.page.config';
import { SLOTS_META } from '@modules/slots/constants/slots.page.config';
import { SPORTSBOOK_META } from '@modules/sportsbook/constants/sportsbook.page.config';
import { TransNamespace } from '../client/modules/translation/TransNamespace';

const Meta = () => {
	return {
		default: {
			titleTemplate: '{{appName}}',
			description:
				"WOLFBET • No. 1 Cryptocurrency casino on the market ✓ Advanced Dice game ✓ 1000's Slots games ✓ Live Casino ✓ Sportsbook ➤ Join Wolf community and have fun! Coins we support {{list_currencies}}",
			descriptionProps: {
				list_currencies: Object.keys(TokenNames)
					.map((token) => `${token.toUpperCase()}`)
					.join(', '),
			},
		},
		home: {
			title: 'Bitcoin Casino & Gambling - Crypto Casino',
		},
		faq: {
			title: 'FAQs',
			namespace: TransNamespace.FAQ_PAGE,
		},
		login: {
			title: 'Login',
			namespace: TransNamespace.LOGIN_PAGE,
		},
		terms: {
			title: 'Terms & Conditions',
		},
		register: {
			title: 'Register / Sign-up',
			namespace: TransNamespace.REGISTER_PAGE,
		},
		provablyFair: {
			title: 'Provably Fair',
			namespace: TransNamespace.PROVABLY_FAIR_PAGE,
		},
		ranking: {
			title: 'Top Ranking Crypto Casino Players',
		},
		promotions: {
			title: 'Promotions',
		},
		remindPassword: {
			title: 'Forgot Password - Password Reminder',
		},
		user_deposit: {
			title: 'Deposit',
		},
		user_settings: {
			title: 'Settings',
		},
		user_withdraw: {
			title: 'Withdraw',
		},
		user_affiliate: {
			title: 'Affiliate program',
		},
		user_allTransactions: {
			title: 'Transactions',
		},
		user_vip: {
			title: 'VIP Club - Wolf Pack',
		},
		[ROUTING_ID.USER_VIP_BONUS_CODE]: {
			title: 'VIP Bonus Code',
		},
		[ROUTING_ID.USER_VERIFICATION]: {
			title: 'Verification',
		},
		user_newPassword: {
			title: 'New password',
		},
		user_account_blocked: {
			title: 'Blocked',
		},
		user_account_blockedError: {
			title: 'Error',
		},
		user_email_verificationFailed: {
			title: 'Error',
		},
		user_email_verified: {
			title: 'Verified',
		},
		[ROUTING_ID.USER_SPORTS_HISTORY]: {
			title: 'Sports Bets History',
			namespace: TransNamespace.SPORTS,
		},
		[ROUTING_ID.BETS]: {
			title: 'Bets',
		},
		...SPORTS_META,
		...SLOTS_META,
		...SPORTSBOOK_META,
	};
};

export default Meta;
