export const MODAL_ID = {
	USER_DETAILS: 'userDetails',
	VIP_WHEEL: 'vipWheel',
	LAST_RACE: 'lastRace',
	CURRENT_RACE: 'currentRace',
	AUTOBET: 'autobet',
	SESSION: 'session',
	EDIT_AUTOBET_STRATEGY: 'editAutobetStrategy',
	CONDITION_BLOCK_EDIT: 'conditionBlockEdit',
	REMIND_2FA: 'remind2fa',
	NEW_ACCOUNT: 'new_account',
	LOGOUT: 'logout',
	INSTALL_2FA: 'install2fa',
	UNINSTALL_2FA: 'uninstall2fa',
	LICENSE: 'license',
	RACE_INFO: 'raceInfo',
	TIP: 'tip',
	NEW_FEATURES: 'newFeatures',
	RAIN: 'rain',
	CHAT: 'chatRules',
	SAVE_AUTOBET_STRATEGY: 'saveAutobetStrategy',
	VIP_LEVEL_INCREASED: 'vipLevelIncreased',
	LIVE_SUPPORT_ERROR: 'liveSupportError',
	BAN_USER: 'banUser',
	CHANGE_CLIENT_SEED: 'changeClientSeed',
	REFRESH: 'refresh',
	SECURITY_WARNING: 'securityWarning',
	REQUIRED_2FA: 'requiredTwoFa',
	INPUT_EXTEND: 'inputExtend',
	BET: 'bet',
	SESSION_ENDED: 'session_ended',
	AFFILIATE_CAMPAIGN: 'affiliate_campaign',
	VAULT: 'vault',
	STRATEGY: 'strategy',
	COMPATIBLE_GAMES: 'compatibleGames',
	EXPERT_INFO: 'expertInfo',
	CLOUDFLATE_ERROR: 'cloudflareError',
	RESPONSE_INFO: 'responseInfo',
	SPORTS_SEARCH: 'sportsSearch',
	TRIVIA: 'trivia',
	TRIVIA_WINNER: 'trivia_winner',
	SLOT_SEARCH: 'slot_search',
	MAINTENANCE_MODULE: 'maintenance_module',
	REQUIRED_KYC: 'required_kyc',
	REQUIRED_ACTION_KYC: 'required_action_kyc',
	CONTENT: 'content',
	WALLET: 'wallet',
	RAKE_BACK: 'rake_back',
	INSANE_BONUS_CODE_INFO: 'insane_bonus_code_info',
	BONUS_CODE: 'bonus_code',
	WELCOME_MODAL: 'welcome_modal',
} as const;

type ModalIdKeys = keyof typeof MODAL_ID;
export type ModalIdType = (typeof MODAL_ID)[ModalIdKeys];
