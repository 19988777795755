import JSONPath from 'JSONPath';
import {
	NEW_BET,
	NEW_HIGH_ROLL,
	NEW_MY_BET,
} from '@legacyApp/client/store/transactions/transactions.constants';
import { getTimestamp } from '@legacyApp/client/modules/app/timeService';
import { config } from '@legacyApp/client/config';
import { lsSet } from '@modules/localStorage/methods/lsSet';
import { lsGet } from '@modules/localStorage/methods/lsGet';
import { lsClear } from '@modules/localStorage/methods/lsClear';
import { LocalStorageKeys } from '@modules/localStorage/types/localStorageKeys.type';
import { jsonToString } from '@common/methods/jsonToString';
import { generateUserVipRakeBackFunds } from '../modals/components/RakebackModal/generators/generateUserVipRakeBackFunds';
import sortService, {
	DIRECTION,
	Direction,
} from '../../LegacyApp/client/modules/app/sortService';
import { TABLE_ID } from './constants/TableId';

interface AddToListProps {
	label: string;
	element: any;
	list: Array<any>;
	direction?: DIRECTION;
	limit?: number;
	keys?: Array<string>;
	disableSort?: boolean;
}

class transactionsClass {
	limit: number | undefined;
	tablesRowCompareKeys: Record<string, Array<string>>;
	tablesTableCompareKeys: Record<string, Array<string>>;

	constructor() {
		this.limit = undefined;
		this.tablesRowCompareKeys = {
			[TABLE_ID.affiliateCampaigns]: ['commission_rate', 'ftds', 'deposits'],
			[TABLE_ID.affiliateFunds]: ['available', 'withdrawn', 'total_commission'],
			[TABLE_ID.affiliateAnalytics]: [
				'hits',
				'referrals',
				'ftds',
				'deposits',
				'available',
				'commission',
			],
			[TABLE_ID.rakebackFunds]: ['available', 'withdrawn', 'total_commission'],
			[TABLE_ID.raceResults]: ['waggered', 'difference', 'position', 'login'],
			profitHistory: ['x', 'y'],
			[TABLE_ID.withdrawHistory]: ['uuid', 'state'],
			[TABLE_ID.depositHistory]: ['hash', 'state'],
			[TABLE_ID.myBets]: [
				'hash',
				'server_seed',
				'user_seed',
				'accounted_at',
				'multiplier',
			],
			[TABLE_ID.leaderboard]: [
				'id',
				'bets',
				'currency',
				'wager',
				'profit',
				'tips',
			],
		};
		this.tablesTableCompareKeys = {
			[TABLE_ID.affiliateCampaigns]: ['id'],
			[TABLE_ID.affiliateFunds]: ['id'],
			[TABLE_ID.rakebackFunds]: ['id'],
			[TABLE_ID.affiliateAnalytics]: ['id'],
			[TABLE_ID.withdrawHistory]: ['uuid'],
			[TABLE_ID.depositHistory]: ['hash'],
			[TABLE_ID.myBets]: ['hash'],
		};
	}

	getIdFromActionType(type: string) {
		switch (type) {
			case NEW_MY_BET:
				return TABLE_ID.myBets;
			case NEW_BET:
				return TABLE_ID.allBets;
			case NEW_HIGH_ROLL:
				return TABLE_ID.highRolls;
		}
	}

	getTableCompareKeys(type, data?: any) {
		if (!this.tablesTableCompareKeys[type]) {
			this.tablesTableCompareKeys[type] =
				this.tablesRowCompareKeys[type] || this.getRowCompareKeys(type, data);
		}
		return this.tablesTableCompareKeys[type];
	}

	getRowCompareKeys(type, data?: any) {
		if (!this.tablesRowCompareKeys[type]) {
			this.tablesRowCompareKeys[type] =
				data?.length && data.length > 1
					? this.getCompareKeys(data[0], data[1])
					: null;
		}
		return this.tablesRowCompareKeys[type];
	}

	listStringify(list, keys?: Array<string>) {
		return Array.isArray(list)
			? list
					.map((el) =>
						typeof el === 'object' ? this.getCompareData(el, keys) : el,
					)
					.join(',')
			: list;
	}

	isListDifferent = (listA, listB, keys?: Array<string>) => {
		return this.listStringify(listA, keys) !== this.listStringify(listB, keys);
	};

	isListEqual = (listA, listB, keys?: Array<string>) => {
		return !this.isListDifferent(listA, listB, keys);
	};

	isDataDifferent = (dataA, dataB, keys?: Array<string>) => {
		return (
			this.getCompareData(dataA, keys) !== this.getCompareData(dataB, keys)
		);
	};

	addOnlyLastToList(props: AddToListProps) {
		if (props?.list?.length) {
			const { element, list } = props;
			const firstElementDate = getTimestamp(
				list[0].published_at || list[0].created_at,
			);
			const elementDate = getTimestamp(
				element.published_at || element.created_at,
			);
			if (firstElementDate > elementDate) {
				return list;
			}
		}
		return this.addNewToList(props);
	}

	addElementToList(props: AddToListProps) {
		const { list, element, direction, limit, keys, disableSort } = props;

		if (list.length) {
			const index = list.findIndex((el) => this.isDuplicate(el, element, keys));
			if (index > -1) {
				const list_ = [...list];
				list_.splice(index, 1, element);
				// logger(`addNewToList duplicate ${label}`, {element, list, index});
				return list_;
			}
		}
		// logger(`addNewToList newElement ${label}`, {element, list,});
		const list_ =
			direction === DIRECTION.DESC ? [element, ...list] : [...list, element];
		if (disableSort) {
			return list_.slice(0, limit);
		}
		return this.sortTransactions(list_, direction, limit);
	}

	addArrayToList(props: AddToListProps) {
		const { list, element, direction, limit, keys, disableSort } = props;
		if (!list.length && !element.length) {
			return list;
		}
		// logger(`addNewToList concat ${label}`, {element, list});
		if (!list.length && element.length) {
			if (disableSort) {
				return element.slice(0, limit);
			}
			return this.sortTransactions(element, direction, limit);
		}
		if (disableSort) {
			return element
				.concat(
					list.filter(
						(el) => !element.some((obj) => this.isDuplicate(el, obj, keys)),
					),
				)
				.slice(0, limit);
		}
		return this.sortTransactions(
			element.concat(
				list.filter(
					(el) => !element.some((obj) => this.isDuplicate(el, obj, keys)),
				),
			),
			direction,
			limit,
		);
	}

	addNewToList(props: AddToListProps) {
		// console.log('addNewToList', {
		//   label,
		//   element,
		//   list,
		//   direction,
		//   limit,
		//   keys,
		// })
		const list = props.list.length === undefined ? [] : props.list;
		if (props.list.length === undefined) {
			props.list = [];
		}
		const props_: AddToListProps = {
			...props,
			list,
		};
		if (Array.isArray(props.element)) {
			return this.addArrayToList(props_);
		}
		return this.addElementToList(props_);
	}

	sortTransactions(
		list,
		direction: Direction = DIRECTION.DESC,
		limit = this.limit,
	) {
		// workerService.process('sortTransactions', {list, direction, limit}, event => event.data).then(result => {
		//   console.log(result);
		// });
		if (!limit) {
			limit = list.length;
		}
		return list
			.sort((a, b) =>
				sortService.sort(
					direction,
					getTimestamp(a.accounted_at || a.published_at || a.created_at),
					getTimestamp(b.accounted_at || b.published_at || b.created_at),
					a.nonce,
					b.nonce,
				),
			)
			.slice(0, limit);
	}

	isDuplicate(a, b, compareKeys?: Array<string>, isPrecise?: boolean) {
		if (!a && !b) {
			return true;
		}
		if ((!a && b) || (!b && a)) {
			return false;
		}
		if (!compareKeys) {
			compareKeys = this.getCompareKeys(a, b, isPrecise);
		}
		const dataA = this.getCompareData(a, compareKeys);
		const dataB = this.getCompareData(b, compareKeys);

		// if (a.hash && a.hash === b.hash) console.log('isDuplicate', a.hash, {a, b, getTableCompareKeys, dataA, dataB, bool: dataA === dataB});

		return dataA === dataB;
	}

	getCompareKeys(a, b, isPrecise?: boolean) {
		if (a.uuid && ((b && b.uuid) || !b)) {
			return ['uuid'];
		}

		if (
			isPrecise &&
			(a.nonce || a.nonce === 0) &&
			a.server_seed_hashed &&
			a.user_seed &&
			((b &&
				(b.nonce || b.nonce === 0) &&
				b.server_seed_hashed &&
				b.user_seed) ||
				!b)
		) {
			return ['nonce', 'server_seed', 'user_seed', 'server_seed_hashed'];
		}

		if (a.hash && ((b && b.hash) || !b)) {
			return ['hash'];
		}

		if (
			(a.nonce || a.nonce === 0) &&
			a.server_seed_hashed &&
			a.user_seed &&
			((b &&
				(b.nonce || b.nonce === 0) &&
				b.server_seed_hashed &&
				b.user_seed) ||
				!b)
		) {
			return ['nonce', 'server_seed', 'user_seed', 'server_seed_hashed'];
		}

		if (a.id && ((b && b.id) || !b)) {
			return ['id'];
		}

		if (a.nonce && ((b && b.nonce) || !b)) {
			return ['nonce'];
		}

		if (a.accounted_at && ((b && b.accounted_at) || !b)) {
			return ['accounted_at'];
		}

		if (a.created_at && ((b && b.created_at) || !b)) {
			return ['created_at'];
		}

		if (a.published_at && ((b && b.published_at) || !b)) {
			return ['published_at'];
		}

		if (a.position && ((b && b.position) || !b)) {
			return ['position'];
		}

		return null;
	}

	getCompareData = (obj, keys?: Array<string>) => {
		if (!keys) {
			return jsonToString(obj);
		}
		if (!obj) {
			return String(obj);
		}
		if (Array.isArray(obj)) {
			return obj
				.map((el) =>
					typeof el === 'object' ? this.getCompareData(el, keys) : el,
				)
				.join(',');
		}
		let result = '';
		for (let i = 0; i < keys.length; i++) {
			result +=
				keys[i].indexOf('.') > -1
					? JSONPath({
							path: keys[i],
							json: obj,
					  })[0]
					: obj[keys[i]];
		}
		if (result === 'undefined') {
			return jsonToString(obj);
		}
		return result;
	};

	getBetFromPending(data) {
		if (!data || !data.length) {
			return false;
		}
		return data.pop();
	}

	saveMyBetsToMemory(data = [], uuid) {
		lsSet(
			LocalStorageKeys.MY_BETS_COPY,
			data.length && uuid
				? data.slice(0, 20).filter((el) => el?.user?.uuid === uuid)
				: data,
		);
	}

	getMyBetsCopyFromMemory(uuid) {
		let data = lsGet(LocalStorageKeys.MY_BETS_COPY_CLEARED)
			? lsGet(LocalStorageKeys.MY_BETS_COPY)
			: false;
		if (!data) {
			lsClear(LocalStorageKeys.MY_BETS_COPY);
			lsSet(LocalStorageKeys.MY_BETS_COPY_CLEARED, true);
			data = [];
		}
		return data.filter((el) => el.user.uuid === uuid);
	}

	concatMyBetsFromFetch(
		data,
		uuid,
		limit = config.transactionsPaginationLength,
	) {
		const memoryData = this.getMyBetsCopyFromMemory(uuid);
		if (!memoryData.length) {
			return data;
		}
		const tempData = memoryData.filter(
			(memoryEl) => !data.some((el) => this.isDuplicate(el, memoryEl)),
		);
		return tempData.concat(data).slice(0, limit);
	}

	getTransactionsUri({ id, page, disable, props }) {
		let uri: boolean | string | { uri: string } = false;
		let withPagination = true;
		if (id === TABLE_ID.vaultHistory) {
			uri = `/user/vault/history${props.type ? `?type=${props.type}` : ''}`;
		}
		if (id === TABLE_ID.rakebackFunds && !disable) {
			uri = generateUserVipRakeBackFunds();
		}
		if (id === TABLE_ID.affiliateFunds && !disable) {
			withPagination = false;
			uri = '/user/affiliate/funds';
		}
		if (id === TABLE_ID.affiliateAnalytics && !disable) {
			uri = '/user/affiliate/analytics';
		}
		if (id === TABLE_ID.affiliateCashoutHistory && !disable) {
			uri = '/user/affiliate/cashout_history';
		}
		if (id === TABLE_ID.affiliateCampaigns && !disable) {
			uri = '/user/affiliate/campaigns';
		}
		if (id === TABLE_ID.withdrawHistory && !disable) {
			uri = '/user/withdraws';
		}
		if (id === TABLE_ID.otherTransactions && !disable) {
			uri = '/user/transactions';
		}
		if (id === TABLE_ID.depositHistory && !disable) {
			uri = '/user/deposits';
		}
		if (id === TABLE_ID.friendsIgnored) {
			uri = '/user/friends/ignoring';
		}
		if (id === TABLE_ID.sessions) {
			uri = '/v2/user/sessions';
		}
		if (id === TABLE_ID.sessionHistory && props) {
			uri = `/v2/session/history/${props.id}`;
		}
		if (uri && page && withPagination) {
			if (typeof uri === 'object') {
				// @ts-expect-error invalid type definition
				const separator = uri.uri.indexOf('?') > -1 ? '&' : '?';
				// @ts-expect-error invalid type definition
				uri.uri = `${uri.uri}${separator}page=${page}`;
			} else {
				const separator = uri.indexOf('?') > -1 ? '&' : '?';
				uri = `${uri}${separator}page=${page}`;
			}
		}
		return uri;
	}
}

export const blockGetTransaction = (action, state) => {
	const isUpdate = state.transactions.update[action.id];
	const isFulfilled = state.transactions.fulfilled[action.id];
	const check =
		!action.page &&
		!action.refresh &&
		((isFulfilled && !action.disable) || !action.disable === isUpdate);
	// console.log('blockGetTransaction', {
	// 	action,
	// 	isUpdate,
	// 	isFulfilled,
	// 	check,
	// 	res: check || action.disable,
	// });
	return check || action.disable;
};

const transactionService = new transactionsClass();

export default transactionService;
