import hoistNonReactStatics from 'hoist-non-react-statics';
import prevalCurrenciesSettings from '../../LegacyApp/methods/preval/currenciesSettings.preval';

export const appWithPrevalProps = (WrappedComponent) => {
	const AppWithPrevalProps = (props) => {
		if (!props?.pageProps?._nextI18Next) {
			return <WrappedComponent {...props} />;
		}
		// const nextI18NextPageProps = getNextI18NextPageProps(props.pageProps);
		const prevalProps = {
			...props,
			pageProps: {
				...props.pageProps,
				// ...nextI18NextPageProps,
				currenciesSettingsData: prevalCurrenciesSettings,
			},
		};
		// console.log('appWithPrevalProps', props.pageProps._nextI18Next, prevalProps.pageProps._nextI18Next);
		return <WrappedComponent {...prevalProps} />;
	};
	return hoistNonReactStatics(AppWithPrevalProps, WrappedComponent);
};
