import { combineReducers } from 'redux';
import {
	CLEAR_CONFIRM,
	SET_CONFIRM,
	SET_CONFIRM_CALLBACK,
	SET_CONFIRM_CALLBACK_CANCEL,
} from './confirm.constants';

const openReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_CONFIRM:
			return true;
		case CLEAR_CONFIRM:
			return false;
		default:
			return state;
	}
};

const callbackReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_CONFIRM:
			return action.payload.callback || false;
		case SET_CONFIRM_CALLBACK:
			return action.payload || false;
		case CLEAR_CONFIRM:
			return false;
		default:
			return state;
	}
};

const questionReducer = (
	state = '',
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_CONFIRM:
			return action.payload.question || 'Are you sure?';
		case CLEAR_CONFIRM:
			return '';
		default:
			return state;
	}
};

const dataReducer = (
	state = {},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_CONFIRM:
			return action.payload.data || {};
		case CLEAR_CONFIRM:
			return {
				data: {},
			};
		default:
			return state;
	}
};

const callbackCancelReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_CONFIRM:
			return action.payload.callbackCancel || false;
		case SET_CONFIRM_CALLBACK_CANCEL:
			return action.payload;
		case CLEAR_CONFIRM:
			return false;
		default:
			return state;
	}
};

const dataCancelReducer = (
	state = {},
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_CONFIRM:
			return action.payload.dataCancel || {};
		case CLEAR_CONFIRM:
			return {
				data: {},
			};
		default:
			return state;
	}
};

const textReducer = (
	state = false,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_CONFIRM:
			return action.payload.text || false;
		case CLEAR_CONFIRM:
			return {
				data: false,
			};
		default:
			return state;
	}
};

const lockConfirmReducer = (
	state = null,
	action = {
		type: '',
	},
) => {
	switch (action.type) {
		case SET_CONFIRM:
			return action.payload.lockConfirm || null;
		case CLEAR_CONFIRM:
			return null;
		default:
			return state;
	}
};

const confirmReducer = combineReducers({
	open: openReducer,
	callback: callbackReducer,
	question: questionReducer,
	data: dataReducer,
	callbackCancel: callbackCancelReducer,
	dataCancel: dataCancelReducer,
	text: textReducer,
	lockConfirm: lockConfirmReducer,
});

export { confirmReducer };
