import React from 'react';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server.browser';
import styled from 'styled-components';
import { isFunction } from '@common/methods/isFunction';

const SvgIcon = styled.svg.attrs({
	version: '1.1',
	xmlns: 'http://www.w3.org/2000/svg',
	xmlnsXlink: 'http://www.w3.org/1999/xlink',
})`
	min-width: ${(props) => (props.minWidth ? `${props.minWidth}px` : 'unset')};
	min-height: ${(props) =>
		props.minHeight ? `${props.minHeight}px` : 'unset'};
`;

const Svg = (props) => (
	<SvgIcon
		viewBox={props.viewBox ?? `0 0 ${props.width} ${props.height}`}
		width={props.width}
		fill={props.wrapperFill}
		height={props.height}
		minWidth={props.minWidth}
		style={props.style}
		minHeight={props.minHeight}
		className={props.className}
	>
		{isFunction(props.content) ? props.content(props) : props.content}
	</SvgIcon>
);

Svg.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
	className: PropTypes.string,
	content: PropTypes.any,
	viewBox: PropTypes.string,
	minHeight: PropTypes.number,
	minWidth: PropTypes.number,
	fill: PropTypes.any,
	wrapperFill: PropTypes.any,
	style: PropTypes.any,
};

export { Svg };

export function encodeSvg(reactElement) {
	return (
		'data:image/svg+xml,' +
		escape(ReactDOMServer.renderToStaticMarkup(reactElement))
	);
}
