export const OPEN_MODAL = 'OPEN_MODAL';
export const OPEN_SECURITY_WARNING_MODAL = 'OPEN_SECURITY_WARNING_MODAL';
export const SET_MODAL_SOURCE = 'SET_MODAL_SOURCE';
export const SET_MODAL_CLOSE_CALLBACK = 'SET_MODAL_CLOSE_CALLBACK';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_MODAL_DELAYED = 'CLOSE_MODAL_DELAYED';
export const RESET_MODAL = 'RESET_MODAL';
export const MODAL_SET_PREVIOUS = 'MODAL_SET_PREVIOUS';
export const MODAL_HANDLE_PREVIOUS = 'MODAL_HANDLE_PREVIOUS';
export const MODAL_SET_PROPS = 'MODAL_SET_PROPS';
export const MODAL_UPDATE_PROPS = 'MODAL_UPDATE_PROPS';
export const MODAL_SET_PREVIOUS_PROPS = 'MODAL_SET_PREVIOUS_PROPS';
export const MODAL_OPEN_INFO = 'MODAL_OPEN_INFO';
export const MODAL_CLOSE_INFO = 'MODAL_CLOSE_INFO';
