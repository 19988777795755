import { useCallback } from 'react';
import { useStateEffect } from '@common/hooks/useStateEffect';
import { config } from '../../client/config';
import getMetaData from '../../mocks/meta';
import { useStringTrans } from '../translation/useStringTrans';
import { simpleTrans } from '../../client/modules/translation/translationService';

const META_DATA = getMetaData();

export const useMeta = ({
	title,
	titleTemplate,
	description,
	titleProps = {},
	descriptionProps = null,
	namespace = 'common',
}) => {
	const namespace_ = useStateEffect(
		useCallback(
			() => META_DATA[title]?.namespace || namespace,
			[namespace, title],
		),
	);
	const trans = useStringTrans(namespace_);
	const title_ = useStateEffect(
		useCallback(
			() =>
				META_DATA[title]
					? META_DATA[title].title || META_DATA.default.title
					: title,
			[title],
		),
	);

	const metaTitle = useStateEffect(
		useCallback(() => {
			const titleTemplate_ =
				titleTemplate ||
				(META_DATA[title_] && META_DATA[title_].titleTemplate
					? META_DATA[title_].titleTemplate
					: false);
			const title = titleTemplate_
				? trans(titleTemplate_, titleProps)
				: `${title_ ? '%s | ' : ''}${simpleTrans(
						META_DATA.default.titleTemplate,
						{
							appName: config.appName,
							...(titleProps || {}),
						},
				  )}`;
			if (title_) {
				return title.replace('%s', trans(title_, titleProps));
			}
			return title;
		}, [titleProps, titleTemplate, title_, trans]),
	);

	const metaDescription = useStateEffect(
		useCallback(() => {
			const description_ =
				description ||
				(META_DATA[title_] && META_DATA[title_].description
					? META_DATA[title_].description
					: META_DATA.default.description);
			const descriptionProps_ =
				descriptionProps ||
				(META_DATA[title_] && META_DATA[title_].descriptionProps
					? META_DATA[title_].descriptionProps
					: META_DATA.default.descriptionProps);
			return simpleTrans(description_, descriptionProps_);
		}, [description, descriptionProps, title_]),
	);
	// console.log('useMeta', { meta, title_, titleProps, titleTemplate, description, descriptionProps, namespace_, trans: trans(title_, titleProps) });
	return {
		title: metaTitle,
		description: metaDescription,
	};
};
