export const VIP_CURRENCY = 'usd' as const;

export enum VipLevelType {
	BRONZE = 'bronze',
	SILVER = 'silver',
	GOLD = 'gold',
	DIAMOND = 'diamond',
}

export const VIP_LEVELS_COLORS = {
	1: VipLevelType.BRONZE,
	2: VipLevelType.BRONZE,
	3: VipLevelType.BRONZE,
	4: VipLevelType.BRONZE,
	5: VipLevelType.BRONZE,
	6: VipLevelType.SILVER,
	7: VipLevelType.SILVER,
	8: VipLevelType.SILVER,
	9: VipLevelType.SILVER,
	10: VipLevelType.SILVER,
	11: VipLevelType.GOLD,
	12: VipLevelType.GOLD,
	13: VipLevelType.GOLD,
	14: VipLevelType.GOLD,
	15: VipLevelType.GOLD,
	16: VipLevelType.DIAMOND,
} as const;

export enum VipBenefits {
	LEVEL_UP_BONUS = 'levelUpBonus',
	WEEKLY_BONUS_CODE = 'weeklyBonusCode',
	DAILY_CHARGE = 'dailyCharge',
	RAKEBACK = 'rakeback',
	WOLF_RACE = 'wolfRace',
	PRIORITY_SUPPORT = 'prioritySupport',
	INSANE_BONUS_CODE = 'insaneBonusCode',
	RAIN_GIVEAWAYS = 'rainGiveaways',
	CHAT_RAIN = 'chatRain',
	OTHER = 'other',
	BONUS_CODE = 'bonusCode',
}
