import { Trans, useTranslation } from 'next-i18next';
import { FC, useCallback, useEffect } from 'react';
import { translationService } from '@legacyApp/client/modules/translation/translationService';
import { useAddNamespace } from '@legacyApp/hooks/translation/useAddNamespace';
import { TransNamespace } from '@legacyApp/client/modules/translation/TransNamespace';
import { DEFAULT_NAMESPACE } from '@legacyApp/client/modules/translation/translate.constants';
import { useForceUpdateWithTimeout } from '@legacyApp/hooks/app/useForceRerender';
import { getTranslationFromInstance } from '@legacyApp/methods/i18n/getTranslationFromInstance';
import { useStateEffect } from '@common/hooks/useStateEffect';

const Interpolate: FC<{
	translation: string;
	options: Record<string, string | number>;
}> = ({ translation, options }) => {
	return <>{translationService.interpolate(translation, options)}</>;
};

export const TranslateWrapper = ({
	translateKey,
	options,
	components,
	namespace = DEFAULT_NAMESPACE,
	noTranslationCheck = false,
}: {
	translateKey: any;
	options?: Record<string, any>;
	components?: any;
	translation?: string;
	namespace?: TransNamespace;
	noTranslationCheck?: boolean;
}) => {
	const { t, i18n, ready } = useTranslation(namespace);
	useAddNamespace({ key: translateKey, namespace, noTranslationCheck });
	const forceRerender = useForceUpdateWithTimeout({
		timeout: 300,
		counter: 10,
	});

	const get = useCallback(() => {
		if (!ready) {
			return null;
		}
		return getTranslationFromInstance({
			i18n,
			namespace,
			key: translateKey,
		});
	}, [i18n, namespace, ready, translateKey]);
	const translation = useStateEffect(get);

	useEffect(() => {
		if (!ready) {
			return;
		}
		if (!translation) {
			forceRerender();
		}
	}, [forceRerender, ready, translation]);

	if (!ready) {
		return translateKey;
	}

	if (components) {
		const TransProps = {
			i18n,
			i18nKey: translateKey,
			namespace,
			values: options,
			t,
			components: components?.props?.children,
		};
		return (
			<>
				{/*<span className='text-gold'>*/}
				{/*	translateKey: {translateKey} / namespace: {namespace} / options: {jsonToString(options)} / components: {components || 'null'}*/}
				{/*</span>{' '}*/}
				<Trans {...TransProps}>{components ? undefined : translateKey}</Trans>
			</>
		);
	}
	return (
		<>
			{/*<span className='text-negative'>*/}
			{/*		translation: {translation} / translateKey: {translateKey} / namespace: {namespace} / options: {jsonToString(options)}*/}
			{/*</span>{' '}*/}
			<Interpolate
				translation={translation || translateKey}
				options={options}
			/>
		</>
	);
};
